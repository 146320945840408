import { Button } from "@/components/ui/button";
import Header from "@/components/Header";
import { Link } from "react-router";

export default function AboutUs() {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-1 container py-12">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold mb-8">About QuizBase</h1>
          
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
            <p className="text-lg text-gray-700 mb-4">
              At QuizBase, we're on a mission to transform the way people learn and assess knowledge. 
              We believe that effective learning happens through engagement, and our platform is designed 
              to make the quiz creation and taking experience both powerful and enjoyable.
            </p>
            <p className="text-lg text-gray-700">
              Our goal is to provide educators, trainers, and content creators with the tools they need 
              to create meaningful assessments that drive learning outcomes and provide valuable insights.
            </p>
          </section>
          
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
            <p className="text-lg text-gray-700 mb-4">
              QuizBase was founded in 2023 by a team of educators and technologists who were frustrated 
              with the limitations of existing quiz platforms. We set out to build something better—a platform 
              that combines powerful features with an intuitive interface.
            </p>
            <p className="text-lg text-gray-700">
              What started as a simple idea has grown into a comprehensive quiz platform used by educators, 
              businesses, and content creators around the world to create engaging learning experiences.
            </p>
          </section>
          
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Our Team</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="w-20 h-20 bg-gray-200 rounded-full mb-4"></div>
                <h3 className="text-xl font-medium">Sarah Johnson</h3>
                <p className="text-gray-500 mb-2">Co-Founder & CEO</p>
                <p className="text-gray-700">
                  Former educator with 10+ years of experience in EdTech, passionate about 
                  making learning accessible and engaging for everyone.
                </p>
              </div>
              
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="w-20 h-20 bg-gray-200 rounded-full mb-4"></div>
                <h3 className="text-xl font-medium">Michael Chen</h3>
                <p className="text-gray-500 mb-2">Co-Founder & CTO</p>
                <p className="text-gray-700">
                  Software engineer with a background in learning management systems and 
                  a vision for building tools that empower educators.
                </p>
              </div>
              
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="w-20 h-20 bg-gray-200 rounded-full mb-4"></div>
                <h3 className="text-xl font-medium">Aisha Patel</h3>
                <p className="text-gray-500 mb-2">Head of Product</p>
                <p className="text-gray-700">
                  UX specialist focused on creating intuitive, accessible interfaces that 
                  make complex tasks simple and enjoyable.
                </p>
              </div>
              
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="w-20 h-20 bg-gray-200 rounded-full mb-4"></div>
                <h3 className="text-xl font-medium">David Rodriguez</h3>
                <p className="text-gray-500 mb-2">Head of Customer Success</p>
                <p className="text-gray-700">
                  Dedicated to helping our users get the most out of QuizBase, with a 
                  background in educational consulting and customer support.
                </p>
              </div>
            </div>
          </section>
          
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
            <ul className="space-y-4">
              <li className="flex items-start">
                <div className="bg-blue-100 text-blue-600 p-2 rounded-full mr-4 mt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-medium">Accessibility</h3>
                  <p className="text-gray-700">We believe learning tools should be accessible to everyone, regardless of ability or background.</p>
                </div>
              </li>
              
              <li className="flex items-start">
                <div className="bg-green-100 text-green-600 p-2 rounded-full mr-4 mt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-medium">Innovation</h3>
                  <p className="text-gray-700">We're constantly pushing the boundaries of what's possible in assessment and learning.</p>
                </div>
              </li>
              
              <li className="flex items-start">
                <div className="bg-purple-100 text-purple-600 p-2 rounded-full mr-4 mt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-medium">User-Centered Design</h3>
                  <p className="text-gray-700">Everything we build starts with understanding the needs of our users.</p>
                </div>
              </li>
            </ul>
          </section>
          
          <div className="flex justify-center mt-10">
            <Link to="/">
              <Button className="mr-4">Back to Home</Button>
            </Link>
            <Link to="/login">
              <Button variant="outline">Get Started</Button>
            </Link>
          </div>
        </div>
      </main>
      
      <footer className="bg-gray-50 py-8">
        <div className="container text-center text-gray-500">
          <p>© {new Date().getFullYear()} QuizBase. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
