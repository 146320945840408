import { create } from "zustand";
import { NewQuestionRequest, Question } from "@/types/question";
import { QuestionService } from "@/services/question.service";
import { supabase } from "@/lib/supabase";
import { Choice } from "@/types/question";

interface QuestionsState {
  bankId: string | null;
  quizId: string | null;
  questions: Question[];
  questionService: QuestionService | null;
  setBankId: (bankId: string | null) => void;
  setQuizId: (quizId: string | null) => void;
  setQuestionService: (service: QuestionService) => void;
  setQuestions: (questions: Question[]) => void;
  addQuestion: (question: NewQuestionRequest) => Promise<{ error: Error | null }>;
  createMany: (questions: NewQuestionRequest[]) => Promise<{ error: Error | null }>;
  importQuestions: (questions: any[]) => Promise<{ error: Error | null }>;
  updateQuestion: (id: string, question: Question) => Promise<void>;
  deleteQuestion: (id: string) => Promise<void>;
  reorderQuestions: (fromIndex: number, toIndex: number) => Promise<void>;
  refreshQuestions: () => Promise<void>;
}

export const useQuestionsStore = create<QuestionsState>((set, get) => ({
  bankId: null,
  quizId: null,
  questions: [],
  questionService: null,

  setBankId: (bankId: string | null) => set({ bankId }),
  setQuizId: (quizId: string | null) => set({ quizId }),

  setQuestionService: (service) => set({ questionService: service }),

  setQuestions: (questions) => set({ questions }),

  addQuestion: async (question) => {
    const { questionService, refreshQuestions } = get();
    if (!questionService) return { error: Error("Question service not found") };

    const { error } = await questionService.create(question);
    if (error) return { error };

    refreshQuestions();

    return { error: null }
  },

  createMany: async (questions) => {
    const { questionService, refreshQuestions } = get();
    if (!questionService) return { error: Error("Question service not found") };

    const { error } = await questionService.createMany(questions);
    if (error) return { error }

    refreshQuestions();
    return { error: null }
  },

  updateQuestion: async (id, question) => {
    const { questionService } = get();
    if (!questionService) return;

    try {
      const { error } = await questionService.update(id, question);
      if (error) throw error;

      set((state) => ({
        questions: state.questions.map(q =>
          q.id === id ? question : q
        )
      }));
    } catch (error) {
      console.error("Failed to update question:", error);
      throw error;
    }
  },

  deleteQuestion: async (id) => {
    const { questionService, questions } = get();
    if (!questionService) return;

    try {
      const question = questions.find(q => q.id === id);
      if (!question) return;

      const { error } = await questionService.delete(id, question.media);
      if (error) throw error;

      set((state) => ({
        questions: state.questions.filter(q => q.id !== id)
      }));
    } catch (error) {
      console.error("Failed to delete question:", error);
      throw error;
    }
  },

  reorderQuestions: async (fromIndex: number, toIndex: number) => {
    const { questionService, questions } = get();
    if (!questionService) return;

    try {
      const newQuestions = [...questions];
      const [movedQuestion] = newQuestions.splice(fromIndex, 1);
      newQuestions.splice(toIndex, 0, movedQuestion);

      // Update indexes
      newQuestions.forEach((q, i) => {
        q.index = i;
      });

      // Update in database
      const updatePromises = newQuestions.map((q, index) => {
        return supabase.from("questions").update({ index: index }).eq('id', q.id)
      })
      const results = await Promise.all(updatePromises);
      if (results.some(r => r.error)) throw new Error("Failed to reorder questions");

      set({ questions: newQuestions });
    } catch (error) {
      console.error("Failed to reorder questions:", error);
      throw error;
    }
  },

  refreshQuestions: async () => {
    const { questionService } = get();
    if (!questionService) return;

    try {
      const { data, error } = await questionService.load({ limit: 50 });
      if (error) throw error;
      if (data) {
        set({ questions: data });
      }
    } catch (error) {
      console.error("Failed to refresh questions:", error);
      throw error;
    }
  },

  importQuestions: async (importedQuestions) => {
    const { questionService, bankId, quizId, refreshQuestions, questions: originalQuestions } = get();
    if (!questionService) return { error: Error("Question service not found") };

    // now since we have the quetions and choices as strings, lets prepare them with proper ids and resolve the id
    // for the correct answer, then updates the database.
    const questions: NewQuestionRequest[] = importedQuestions.map((q, index) => {
      const choices = q.choices.map((c: string) => ({ id: crypto.randomUUID(), content: c }))
      const correctAnswerId = choices.find((c: Choice) => c.content === q.correctAnswer)?.id
      const qId = crypto.randomUUID()

      return {
        id: qId,
        index: index + originalQuestions.length,
        question: q.question,
        type: q.type,
        choices,
        correct_answer_id: correctAnswerId,
        bank_id: bankId,
        quiz_id: quizId,
      } as NewQuestionRequest;
    })

    // save to database
    const { error } = await questionService.createMany(questions);
    if (error) return { error }

    refreshQuestions();
    return { error: null }
  }
})); 