import { Separator } from "@/components/ui/separator";
import { Choice } from "@/types/question";
import { useCallback, useState } from "react";

export default function MCQuestionCard({
    index,
    id,
    question,
    choices,
    image,
    onAnswer,
}: {
    index: number;
    id: string;
    question: string;
    choices: Choice[];
    image: string | null;
    onAnswer: (questionId: string, choiceId: string) => void;
}) {
    const [answer, setAnswer] = useState<string>();
    const sendAnswer = useCallback(onAnswer, [onAnswer]);

    const onChoiceSelected = (choiceId: string) => {
        setAnswer(choiceId);
        sendAnswer(id, choiceId ?? "");
    };

    if (!question || !choices.length) {
        return <div />;
    }

    return (
        <div className="flex h-full flex-1 flex-col">
            <p className="mb-4 text-xl font-bold select-none">
                {index + 1}. {question}
            </p>
            {image && (
                <div className="mb-6">
                    <img
                        src={image}
                        alt="Question image"
                        className="max-h-64 rounded-lg border object-contain"
                    />
                </div>
            )}
            <div className="flex flex-col space-y-3">
                {choices.map((choice) => (
                    <button
                        key={choice.id}
                        className={`${choice.id === answer
                            ? "border-purple-600 bg-purple-50"
                            : "bg-gray-50/40 hover:border-purple-600 hover:bg-gray-50"
                            } flex w-full items-center gap-2 rounded-lg border p-4 text-left text-lg`}
                        onClick={() => onChoiceSelected(choice.id)}
                    >
                        <input
                            type="radio"
                            checked={choice.id === answer}
                            className="size-[18px] accent-purple-600"
                            onChange={() => { }}
                        />
                        {choice.content}
                    </button>
                ))}
            </div>
            <Separator className="mt-6" />
        </div>
    );
};