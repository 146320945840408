import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { Outlet } from "react-router";

export default function HelpCenter() {


    return (
        <div className="bg-white">
            <Header />
            <Outlet />
            <div className="h-20" />
            <Footer />
        </div>
    );
}
