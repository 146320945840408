import { create } from "zustand";

interface DialogStore {
    dialogs: React.ReactNode[];
    addDialog: (dialog: React.ReactNode) => void;
    removeDialog: (dialog: React.ReactNode) => void;
}

export const useDialogStore = create<DialogStore>((set) => ({
    dialogs: [],
    addDialog: (dialog: any) => set((state) => ({ dialogs: [...state.dialogs, dialog] })),
    removeDialog: (dialog: any) => set((state) => ({ dialogs: state.dialogs.filter(d => d !== dialog) }))
}))