import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { QuizSession } from "@/types/session";
import { humanDateFormat } from "@/lib/utils";
import { format } from "date-fns";
import { Link } from "react-router";

export default function SessionRecord({ session }: { session: QuizSession }) {
    return (
        <Card>
            <CardHeader>
                <CardTitle>{session.quiz.title}</CardTitle>
                <CardDescription>{session.quiz.description}</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col gap-3 h-full flex-1">
                    {session.isCompleted && session.quiz?.config.revealFinalScore && (
                        <a
                            href={`/quiz/${session.quizId}/session/${session.id}/review`}
                            className="rounded-md bg-black px-3 py-1.5 text-sm font-medium text-white hover:bg-gray-800"
                        >
                            View Results
                        </a>
                    )}
                    {!session.isCompleted && (
                        <Button asChild>
                            <Link to={`/quiz/${session.quizId}/session/${session.id}`}>Continue</Link>
                        </Button>
                    )}

                    <div className={`${session.isCompleted ? 'text-green-600 font-medium' : 'text-gray-500'}`}>
                        {session.isCompleted ? "Completed" : session.state || "In progress"}
                    </div>
                    <span className="flex-1" />
                    <p className="text-sm text-gray-500">
                        {format(session.createdAt, humanDateFormat)}
                    </p>
                </div>
            </CardContent>
        </Card>
    )
}
//     return (<div
//         className="flex items-center justify-between rounded-lg bg-white p-4 shadow"
//     >
//         <div>
//             <p className="text-sm text-gray-500">
//                 {format(session.createdAt, humanDateFormat)}
//             </p>
//         </div>
//         <div className="flex items-center gap-3">
//             <div className={`${session.isCompleted ? 'text-green-600 font-medium' : 'text-gray-500'}`}>
//                 {session.isCompleted ? "Completed" : session.state || "In progress"}
//             </div>
//             {session.isCompleted && session.quiz?.config.revealFinalScore && (
//                 <a
//                     href={`/quiz/${session.quizId}/session/${session.id}/review`}
//                     className="rounded-md bg-black px-3 py-1.5 text-sm font-medium text-white hover:bg-gray-800"
//                 >
//                     View Results
//                 </a>
//             )}
//             {!session.isCompleted && (
//                 <Button asChild>
//                     <Link to={`/quiz/${session.quizId}/session/${session.id}`}>Continue</Link>
//                 </Button>
//             )}
//         </div>
//     </div>);
// }