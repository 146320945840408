import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { NavLink } from "react-router";
import { LucideIcon } from "lucide-react";

interface CategoryCardProps {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  articleCount: number;
}

export function CategoryCard({ id, title, description, icon: Icon, articleCount }: CategoryCardProps) {
  return (
    <NavLink to={`/help/category/${id}`}>
      <Card className="hover:shadow-md transition-shadow cursor-pointer h-full">
        <CardHeader>
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-full bg-blue-50">
              <Icon className="h-6 w-6 text-blue-600" />
            </div>
            <CardTitle>{title}</CardTitle>
          </div>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground">{articleCount} articles</p>
        </CardContent>
      </Card>
    </NavLink>
  );
}
