import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { QuestionBank } from "@/types/bank";
import { Link } from "react-router";
import { CardStackIcon } from "@radix-ui/react-icons";

interface BankCardProps {
    bank: QuestionBank;
}

export default function BankCard({ bank }: BankCardProps) {
    return (
        <Link to={`/studio/banks/${bank.id}`} className="block">
            <Card className="h-full hover:bg-accent/50 transition-colors">
                <CardHeader>
                    <div className="flex items-start justify-between">
                        <CardTitle>{bank.title}</CardTitle>
                        <div className="flex items-center gap-1 text-sm text-muted-foreground">
                            <CardStackIcon className="h-4 w-4" />
                            <span>{bank.questionsCount[0].count}</span>
                            <span className="text-xs text-muted-foreground">questions</span>
                        </div>
                    </div>
                </CardHeader>
                <CardContent className="text-xs text-muted-foreground">{bank.description}</CardContent>
            </Card>
        </Link>
    );
} 