import Index from '@/assets/generated/help-center-index.json'
import { Article, ArticleMetadata } from '@/types/help-center';


// Load all articles
export async function getAllArticles(): Promise<ArticleMetadata[]> {
  return Index;
}

// Get a specific article by ID
export async function getArticleById(id: string): Promise<Article | null> {
  const content = fetch(`/help-center/${id}.json`).then(res => res.json());
  return content;
}
