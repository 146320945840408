import { supabase } from "@/lib/supabase";
import { useAuthStore } from "@/store/auth.store";
import { useWorkspaceStore } from "@/store/workspace.store";
import { Button } from "@/components/ui/button";
import { NavLink } from "react-router";

export default function Header() {
  const { user, setUser } = useAuthStore();
  const clearWorkspaces = useWorkspaceStore((state) => state.clear);

  const handleLogout = async () => {
    setUser(undefined);
    clearWorkspaces();
    await supabase.auth.signOut();
  };

  return (
    <div className="container">
      <header className="flex items-center py-4">
        <NavLink to="/" className="mr-8 inline-flex items-center gap-1">
          <div className="rounded-lg bg-black px-1.5 py-0.5 text-white">QB</div>
          <span className="text-lg font-bold text-black">QuizBase</span>
        </NavLink>

        <nav className="hidden items-center gap-3 lg:flex">
          <a
            href="/#solutions"
            className="text-muted-foreground hover:text-black"
          >
            Solutions
          </a>
          <a
            href="/#features"
            className="text-muted-foreground hover:text-black"
          >
            Features
          </a>
          <a
            href="/#pricing"
            className="text-muted-foreground hover:text-black"
          >
            Pricing
          </a>
        </nav>

        <div className="flex-1" />
        <nav className="flex items-center gap-3 z-20">
          {!user && (
            <NavLink
              to="/login"
              className="text-muted-foreground hover:text-black"
            >
              Login
            </NavLink>
          )}
          {user && (
            <NavLink
              to="/studio"
              className="text-muted-foreground hover:text-black"
            >
              Studio
            </NavLink>
          )}
          {user && (
            <Button variant={"ghost"} onClick={handleLogout}>
              Logout
            </Button>
          )}
        </nav>
      </header>
    </div>
  );
}
