import PageShell from "@/components/PageShell";
import { Input } from "@/components/ui/input";
import { listQuestionBanks } from "@/services/bank.service";
import { QuestionBank } from "@/types/bank";
import { CardStackIcon, LayersIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useState, useEffect } from "react";
import { toast } from "sonner";
import QuestionBankModal from "./BankModal";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import BankCard from "./BankCard";

export default function BanksIndex() {
  const workspaceId = useCurrentWorkspaceId();
  const [banks, setBanks] = useState<QuestionBank[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchBanks = async () => {
      const { data: banks, error } = await listQuestionBanks(workspaceId!);
      if (error) {
        toast.error("Failed to fetch banks", {
          dismissible: true,
          description: error.message
        });
        return;
      }
      setBanks(banks);
    };
    fetchBanks();
  }, [workspaceId]);

  return (
    <PageShell items={[
      { label: "Question Banks", icon: <CardStackIcon className="h-4 w-4" /> }
    ]} actions={undefined}>
      <div className="px-3">
        {/* Search and actions bar */}
        <div className="flex flex-row justify-between items-start sm:items-center gap-4 pb-4">
          <div className="relative sm:w-auto max-w-md">
            <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search banks..."
              className="pl-9 w-full bg-white border-gray-200 max-w-[250px]"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <QuestionBankModal />
        </div>

        {/* Empty state */}
        {!banks.length && (
          <div className="rounded-lg border border-dashed p-12 text-center bg-white">
            <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-primary/10">
              <LayersIcon className="h-10 w-10 text-primary" />
            </div>
            <h2 className="mt-6 mb-2 text-2xl font-semibold">No banks yet</h2>
            <p className="mb-6 text-muted-foreground max-w-md mx-auto">
              Create your first bank to get started. Banks are a great way to manage
              your questions and make them reusable.
            </p>
          </div>
        )}

        {/* Banks grid */}
        {banks.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {banks.map(bank => (
              <BankCard key={bank.id} bank={bank} />
            ))}
          </div>
        )}
      </div>
    </PageShell>
  );
}
