import { useEditorStore } from "@/store/editor.store";
import QuestionsEditor from "@/studio/QuestionsEditor";
import { QuestionServiceImpl } from '@/services/question.service'
import { useEffect } from 'react';
import { useQuestionsStore } from "@/store/questions.store";

export default function QuizQuestionsEditor() {
  const { quiz, isEditable } = useEditorStore();
  if (!quiz) {
    return <div>Loading...</div>;
  }
  const questionsStore = useQuestionsStore();


  useEffect(() => {
    questionsStore.setQuestionService(new QuestionServiceImpl(null, quiz.id));
    questionsStore.setBankId(null);
    questionsStore.setQuizId(quiz.id);
    questionsStore.refreshQuestions().then()
  }, [quiz.id]);

  return (
    <QuestionsEditor disabled={!isEditable} />
  )
}
