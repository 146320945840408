"use client";

import { Check, ChevronsUpDown, GalleryVerticalEnd } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { useCurrentWorkspace } from "@/hooks/use-current-ws";
import { useWorkspaceStore } from "@/store/workspace.store";

export function WorkspaceSwitcher() {
  const { workspaces, setCurrentWorkspace, currentWorkspace } =
    useWorkspaceStore();
  const workspace = useCurrentWorkspace();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="sm"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="bg-sidebar-primary text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg">
                <GalleryVerticalEnd className="size-3" />
              </div>
              <div className="flex flex-col gap-0.5 leading-tight">
                <span className="font-semibold text-xs">
                  {workspace?.name}
                </span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width]"
            align="center"
          >
            {workspaces.map((ws, index) => (
              <DropdownMenuItem
                key={ws.id}
                onSelect={() => setCurrentWorkspace(index)}
              >
                {index === currentWorkspace && <Check className="mr-auto" />}
                {ws.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
