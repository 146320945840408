import { useEffect, useState } from "react";
import { HelpSearch } from "./components/HelpSearch";
import { CategoryCard } from "./components/CategoryCard";
import { helpCategories } from "./data/helpCategories";
import { ArticleCard } from "./components/ArticleCard";
import { getAllArticles } from "./utils/markdownLoader";
import { ArticleMetadata } from "@/types/help-center";

export default function HelpCenter() {
  const [_, setArticles] = useState<ArticleMetadata[]>([]);
  const [featuredArticles, setFeaturedArticles] = useState<ArticleMetadata[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const loadedArticles = await getAllArticles();
        setArticles(loadedArticles);
        setFeaturedArticles(loadedArticles.slice(0, 6));
      } catch (error) {
        console.error("Error loading articles:", error);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="container">
        <h1 className="font-semibold text-2xl">Help Center</h1>
      </div>
      <div className="bg-gray-50 text-gray-800 border-t mt-3 border-b mb-6">
        <div className="container pt-12">


          <div className="max-w-6xl mx-auto p-4">
            <div className="text-center mb-8">
              <h1 className="text-3xl font-bold mb-2">
                How can we help you today?
              </h1>
              <p className="text-muted-foreground text-lg">
                Find answers to your questions about QuizBase.
              </p>
            </div>

            {/* Search Bar */}
            <div className="mb-12">
              <HelpSearch />
            </div>

          </div>
        </div>
      </div>
      <div className="container">

        {/* Categories */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-6">Browse by Category</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {helpCategories.map(category => (
              <CategoryCard
                key={category.id}
                id={category.id}
                title={category.title}
                description={category.description}
                icon={category.icon}
                articleCount={category.articleCount}
              />
            ))}
          </div>
        </div>

        {/* Featured Articles */}
        <div>
          <h2 className="text-2xl font-semibold mb-6">Popular Articles</h2>
          {loading ? (
            <div className="text-center py-8">Loading articles...</div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {featuredArticles.slice(0, 6).map(article => article && (
                <ArticleCard
                  key={article.id}
                  id={article.id}
                  title={article.title}
                  excerpt={article.excerpt}
                  category={article.category}
                  tags={article.tags}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
