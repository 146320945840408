import PageShell from "@/components/PageShell";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { supabase } from "@/lib/supabase";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { StudentColumns } from "./StudentColumns";
import { StudentsDataTable } from "./StudentsDataTable";
import { Group, StudentWithMetadata } from "@/types/student";
import { PersonIcon } from "@radix-ui/react-icons";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import ImportStudentsDialog from "./ImportStudentsDialog";

export default function AudienceIndex() {
  const workspaceId = useCurrentWorkspaceId();
  const [students, setStudents] = useState<StudentWithMetadata[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data, error } = await supabase
        .from("audience")
        .select(`id, code, name, profile:profiles(id,name,email,avatar_url), group:audience_groups(id,name)`)
        .eq("workspaceId", workspaceId!)
        .order('code', { ascending: true })
        .returns<StudentWithMetadata[]>();

      if (error) {
        toast.error("Failed to fetch users");
        console.error(error);
        return;
      }

      setStudents(data);

      const { data: groups, error: groupsError } = await supabase
        .from("audience_groups")
        .select("id,name,workspaceId")
        .eq("workspaceId", workspaceId!)
        .order("name")
        .limit(1000)
        .then();
      if (groupsError) {
        console.error(groupsError);
        return;
      }
      setGroups(groups);
    };

    fetchUsers();
  }, [workspaceId]);

  const handleImportStudents = async (students: { code: string, name: string }[], groupId: string | null | undefined) => {
    // import the loaded student data to the datbase
    // attach groupd id if its not empty
    const toLoad = students.map((s) => ({ code: s.code, name: s.name, groupId: groupId, workspaceId: workspaceId!, updatedAt: 'NOW()' }))
    const { error: importError } = await supabase.from('audience').insert(toLoad)
    if (importError) {
      toast.error('Failed to load data', {
        description: importError.message,
        dismissible: true
      })
      return
    }
    setIsImportDialogOpen(false)
    toast.success('Students data loaded successfully', {
      dismissible: true
    })
    // refresh table
    const { data, error } = await supabase
      .from("audience")
      .select(`id, code, name, profile:profiles(*), group:audience_groups(*)`)
      .eq("workspaceId", workspaceId!)
      .returns<StudentWithMetadata[]>();

    if (error) {
      toast.error("Failed to fetch users");
      console.error(error);
      return;
    }

    setStudents(data);
  }

  const filterResults = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const search = new FormData(e.currentTarget).get("search") as string;
    if (search) {
      const { data } = await supabase.from('audience').select(`id, code, name, profile:profiles(*), group:audience_groups(*)`).eq("workspaceId", workspaceId!).ilike('name', `%${search}%`).order('code', { ascending: true }).returns<StudentWithMetadata[]>();
      if (data) {
        setStudents(data)
      }
    } else {
      const { data } = await supabase.from('audience').select(`id, code, name, profile:profiles(*), group:audience_groups(*)`).eq("workspaceId", workspaceId!).order('code', { ascending: true }).returns<StudentWithMetadata[]>();
      if (data) {
        setStudents(data)
      }
    }
  }

  const filterByGroup = async (groupId: string) => {
    if (groups && groupId !== "all") {
      const { data } = await supabase.from('audience').select(`id, code, name, profile:profiles(*), group:audience_groups(*)`).eq("workspaceId", workspaceId!).eq('groupId', groupId).order('code', { ascending: true }).returns<StudentWithMetadata[]>();
      if (data) {
        setStudents(data)
      }
    } else {
      const { data } = await supabase.from('audience').select(`id, code, name, profile:profiles(*), group:audience_groups(*)`).eq("workspaceId", workspaceId!).order('code', { ascending: true }).returns<StudentWithMetadata[]>();
      if (data) {
        setStudents(data)
      }
    }
  }

  return (
    <PageShell items={[
      { label: "Students", icon: <PersonIcon className="h-4 w-4" /> }
    ]} actions={undefined}>

      <div className="flex px-4">
        <form onSubmit={filterResults} className="flex gap-2">
          <Input className="bg-white min-w-[18rem]" name="search" type="text" placeholder="Filter students by name..."
          />
          <Select name="group" onValueChange={filterByGroup}>
            <SelectTrigger name="group" className="max-w-[15rem] bg-white placeholder-muted-foreground">
              <SelectValue placeholder="by group" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="p-2" value="all">All</SelectItem>
              {groups.map((g) => (
                <SelectItem key={g.id} className="p-2" value={g.id}>{g.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </form>
        <span className="flex-1" />
        <ImportStudentsDialog isOpen={isImportDialogOpen} setIsOpen={setIsImportDialogOpen} groups={groups} onSubmit={handleImportStudents} />
      </div>
      <StudentsDataTable data={students} columns={StudentColumns} />
    </PageShell>
  );
}
