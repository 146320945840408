import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { supabase } from "@/lib/supabase";
import { GroupWithMetadata } from "@/types/student";
import { useState, useEffect } from "react";
import { toast } from "sonner";
import { GroupsDataTable } from "./GroupsDataTable";
import { GroupColumns } from "./GroupColumns";
import { StudioBreadcrumbs } from "@/studio/StudioBreadcrumbs";
import { UsersIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "@radix-ui/react-icons";
import GroupDialog from "./GroupDialog";
import React from "react";
import { useDialogStore } from "@/store/dialog.store";
import GroupDialog2 from "./GroupDialog2";
import { ConfirmDeletionDialog } from "@/components/ConfirmDeletionDialog";

export default function GroupsIndex() {
    const workspaceId = useCurrentWorkspaceId()
    const [groups, setGroups] = useState<GroupWithMetadata[]>([]);
    const [isEditingModalOpen, setIsEditingOpen] = useState(false)
    const { addDialog } = useDialogStore()

    useEffect(() => {
        const fetchGroups = async () => {
            const { data, error } = await supabase
                .from("audience_groups")
                .select("id, name, workspaceId, students:audience(count)")
                .eq("workspaceId", workspaceId!)
                .order("name");
            if (error) {
                toast.error("Failed to fetch groups");
                console.error(error);
                return;
            }

            setGroups(data);
        };

        fetchGroups()
    }, [workspaceId]);

    const fetchGroups = async () => {
        const { data, error } = await supabase
            .from("audience_groups")
            .select("id, name, workspaceId, students:audience(count)")
            .eq("workspaceId", workspaceId!)
            .order("name");
        if (error) {
            toast.error("Failed to fetch groups");
            console.error(error);
            return;
        }

        setGroups(data);
    };

    const handleEditGroup = (groupId: string) => {
        const group = groups.find((group) => group.id === groupId)
        if (!group) {
            return
        }
        const onSubmit = async ({ name }: { name: string }): Promise<{ error: Error | null }> => {
            const { error } = await supabase
                .from("audience_groups")
                .update({ name })
                .eq("id", groupId)
            console.log({ error })
            if (error) {
                toast.error("Failed to update group");
                console.error(error);
                return { error };
            }

            toast.success("Group updated successfully");
            await fetchGroups();
            setIsEditingOpen(false);
            return { error: null }
        }
        const elm = React.createElement(GroupDialog2, {
            group: group,
            onSubmit
        })
        addDialog(elm)
    }

    const handleDeleteGroup = (groupId: string) => {
        const group = groups.find((group) => group.id === groupId)
        if (!group) {
            return
        }
        const onSubmit = async (): Promise<{ error: Error | null }> => {
            const { error: studentsError } = await supabase
                .from("audience")
                .delete()
                .eq("groupId", groupId)
            if (studentsError) {
                toast.error("Failed to delete group students", {
                    description: studentsError.message
                });
                return { error: studentsError };
            }

            const { error } = await supabase
                .from("audience_groups")
                .delete()
                .eq("id", groupId)

            if (error) {
                toast.error("Failed to delete group");
                console.error(error);
                return { error };
            }

            toast.success("Group deleted successfully");
            await fetchGroups();
            setIsEditingOpen(false);
            return { error: null }
        }
        const elm = React.createElement(ConfirmDeletionDialog, {
            onConfirm: onSubmit,
            description: `This will delete the group and all its students.`,
        })
        addDialog(elm)
    }



    const handleNewGroupSubmit = async ({ name }: { name: string }) => {
        const { error } = await supabase.from('audience_groups').insert({ name, workspaceId: workspaceId! })
        console.log({ error })
        if (error) {
            toast.error('Failed to create group', {
                description: error.name,
                dismissible: true
            })
            return
        }

        toast.success("group created successfuly", {
            dismissible: true
        })

        setIsEditingOpen(false)
        await fetchGroups()
    }

    return <>
        <StudioBreadcrumbs items={[{ label: 'Groups', icon: <UsersIcon className="w-4 h-4" /> }]}>
            <Button onClick={() => setIsEditingOpen(true)} className="text-xs" size="sm"><PlusIcon /> Create Group</Button>
        </StudioBreadcrumbs>
        <GroupsDataTable data={groups} columns={GroupColumns} onEditGroup={handleEditGroup} onDeleteGroup={handleDeleteGroup} />
        <GroupDialog isOpen={isEditingModalOpen} group={null} onOpenChange={(open) => setIsEditingOpen(open)} onSubmit={handleNewGroupSubmit} />
        <div id="dialogs-area"></div>
    </>;
}