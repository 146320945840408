import { Quiz } from "@/types/quiz";
import { supabase } from "@/lib/supabase";
import { Link, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import WorkspaceInfoCard from "@/components/WorkspaceInfoCard";
import QuizStartScreen from "./QuizStartScreen";
import { useAuthStore } from "@/store/auth.store";
import { createSession } from "@/lib/data/session.service";
import { toast } from "sonner";
import { ensureUserProfile, validateAndLinkAudienceCode } from "@/lib/data/profile.service";
import AudienceCodeModal from "./AudienceCodeModal";
import { QuizSession } from "@/types/session";
import { differenceInMilliseconds, format } from "date-fns";
import { humanDateFormat } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import * as Sentry from '@sentry/react'

export default function QuizLanding() {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [quiz, setQuiz] = useState<Omit<Quiz, "questions"> | null>(null);
  const [showAudienceModal, setShowAudienceModal] = useState(false);
  const [audienceError, setAudienceError] = useState<string | undefined>();
  const [isVerifyingAudience, setIsVerifyingAudience] = useState(false);
  const [previousSessions, setPreviousSessions] = useState<QuizSession[]>([]);
  const [canStart, setCanStart] = useState(false);
  const [afterSchedule, setAfterSchedule] = useState(false);


  // check if quiz can be started
  useEffect(() => {
    if (!quiz) return;
    // if (quiz.config.timeWindow && quiz.config.timeWindow.enabled) {
    // check if current date passes time window duration
    const now = new Date()
    const start = new Date(quiz.config.timeWindow.start)
    const end = new Date(quiz.config.timeWindow.start + (quiz.config.timeWindow.duration * 60000))
    if (differenceInMilliseconds(now, start) > 0 && differenceInMilliseconds(now, end) < 0) {
      setCanStart(true);
    } else if (differenceInMilliseconds(now, end) > 0) {
      setCanStart(false);
      setAfterSchedule(true);
    } else {
      setCanStart(false);
      setAfterSchedule(false);
    }

  }, [quiz?.config.timeWindow])


  useEffect(() => {
    async function getQuiz(id: string): Promise<Quiz> {
      const { data, error } = await supabase
        .from("quizs")
        .select(
          "id,title,description,config,created_at,workspaceId,isPublic,workspace:workspaces(id,name,description,logo), questionsCount:questions(count)",
        )
        .eq("id", id)
        .returns<Quiz[]>()
        .single();
      if (error) {
        if (error.code === "PGRST116") {
          navigate("/404");
        }
        throw error;
      }

      if (user) {
        try {
          await ensureUserProfile({ user: user, userId: user.id });
        } catch (error) {
          console.error("Error ensuring user profile:", error);
          Sentry.captureException(error, {
            extra: {
              userId: user.id,
              action: "ensure_user_profile"
            }
          });
          const e = error as Error
          if (e) {
            toast.error(e.message)
          }
        }
      }

      return { ...data };
    }

    async function listPreviousSessions(quizId: string): Promise<QuizSession[]> {
      if (!user) {
        return [];
      }
      const { data, error } = await supabase
        .from("responses")
        .select("id,quizId,userId,createdAt,isCompleted,state")
        .eq("quizId", quizId)
        .eq("userId", user?.id)
        .returns<QuizSession[]>()
        .order("createdAt", { ascending: false })
        .limit(10);
      if (error) {
        console.error(error);
        return [];
      }

      return data;
    }

    const load = async () => {
      const data = await getQuiz(quizId!);
      setQuiz(data);
      document.title = `${data.title} - QuizBase`;
      setIsLoading(false);
      const prevSess = await listPreviousSessions(quizId!);
      setPreviousSessions(prevSess);
    };
    load();
  }, [navigate, quizId, user]);

  const handleStart = async () => {
    // Can user start a new session?
    // 1. Check if the user has reached the max attempts
    // 2. Check if the quiz requires to match an audience (e.g: student code)
    if (!user) {
      navigate("/login?next=" + window.location.pathname);
      return;
    }

    setShowAudienceModal(true);
    // if (quiz?.config.requireStudentId) {
    //   setShowAudienceModal(true);
    //   return
    // }

    // const { data: session, error } = await createSession(quizId!);
    // if (error) {
    //   if (error && error.code === '403') {
    //     toast.error("You have reached the maximum number of attempts for this quiz.");
    //     return;
    //   }

    //   toast.error(error.message)
    //   return;
    // }
    // navigate(`/quiz/${quizId}/session/${session?.id}`);
  };

  const handleAudienceCodeSubmit = async (code: string) => {
    if (!user || !quiz) return;

    setIsVerifyingAudience(true);
    setAudienceError(undefined);

    try {
      const result = await validateAndLinkAudienceCode(
        user.id,
        code,
        quiz.workspaceId,
        quizId!,
        quiz.config.maxAttempts,
      );

      if (result.success) {
        setShowAudienceModal(false);
        // Continue with quiz session creation
        const { data: session, error } = await createSession(quizId!, result.audience!);
        if (error) {
          if (error.code === '403') {
            toast.error("You have reached the maximum number of attempts for this quiz.");
            return;
          } else if (error.code === '401') {
            toast.error("You must be authenticated");
            return;
          } else if (error.code === 'GROUP_ACCESS_DENIED') {
            toast.error("You are not allowed to access this quiz");
            return;
          }
          toast.error(error.message)
          return;
        }
        navigate(`/quiz/${quizId}/session/${session?.id}`);
      } else {
        toast.error(result.error);
        setAudienceError(result.error);
      }
    } catch (error) {
      console.log(error)
      const e = error as Error;
      if (e.name === '403') {
        toast.error("You have reached the maximum number of attempts for this quiz.");
      } else if (e.name === '401') {
        toast.error("You must be authenticated");
      } else {
        console.error("Error validating student code:", error);
        toast.error("An unexpected error occurred. Please try again.");
        setAudienceError("An unexpected error occurred. Please try again.");
      }

    } finally {
      setIsVerifyingAudience(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 p-8">
        <div className="mx-auto flex max-w-3xl flex-col gap-3 rounded-lg bg-white p-8 shadow">
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <span className="h-12" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <span className="h-12" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
        </div>
      </div>
    );
  }

  if (!quiz) return <div>Quiz not found</div>;

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="mx-auto flex max-w-3xl flex-col gap-4">
        <WorkspaceInfoCard
          id={quiz.workspaceId}
          name={quiz.workspace.name}
          description={quiz.workspace.description}
          logo={quiz.workspace.logo}
        />

        <QuizStartScreen quiz={quiz} onStart={handleStart} lastSession={previousSessions[0]} isLoading={isVerifyingAudience} canStart={canStart} afterSchedule={afterSchedule} />

        <AudienceCodeModal
          isOpen={showAudienceModal}
          onClose={() => setShowAudienceModal(false)}
          onSubmit={handleAudienceCodeSubmit}
          error={audienceError}
          isLoading={isVerifyingAudience}
        />


        {previousSessions.length > 0 && (
          <div className="">
            <h2 className="text-lg font-semibold">Previous attempts</h2>
            <div className="mt-4 space-y-4">
              {previousSessions.map((attempt) => (
                <div
                  key={attempt.id}
                  className="flex items-center justify-between rounded-lg bg-white p-4 shadow"
                >
                  <div>
                    <p className="text-sm text-gray-500">
                      {format(attempt.createdAt, humanDateFormat)}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className={`${attempt.isCompleted ? 'text-green-600 font-medium' : 'text-gray-500'}`}>
                      {attempt.isCompleted ? "Completed" : attempt.state || "In progress"}
                    </div>
                    {attempt.isCompleted && quiz?.config.revealFinalScore && (
                      <a
                        href={`/quiz/${quizId}/session/${attempt.id}/review`}
                        className="rounded-md bg-black px-3 py-1.5 text-sm font-medium text-white hover:bg-gray-800"
                      >
                        View Results
                      </a>
                    )}
                    {!attempt.isCompleted && (
                      <Button asChild>
                        <Link to={`/quiz/${quizId}/session/${attempt.id}`}>Continue</Link>
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
