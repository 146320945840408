import ExcelJS from 'exceljs'
import { Quiz } from '@/types/quiz'
import { Question } from '@/types/question'
import { ResponseWithMetadata } from '@/types/response'
import { format } from 'date-fns'
import { humanDateFormat } from './utils'

export const exportResponses = async (quiz: Quiz, responses: ResponseWithMetadata[]) => {
    const { workbook, sheet } = buildWorkbook({ sheetName: 'Responses' })


    // FIXME: Add workspace information to the header
    // prepare header
    // if (quiz.workspace?.logo) {
    //     const logo = workbook.addImage({
    //         base64: quiz.workspace.logo,
    //         extension: 'png',
    //     })
    //     sheet.addImage(logo, {
    //         tl: { col: 0, row: 0 },
    //         br: { col: 2, row: 2 },
    //     })
    // }

    // add empty rows
    sheet.addRow(['']).commit()
    sheet.addRow(['']).commit()
    sheet.addRow(['']).commit()

    // add date to the right
    const aboveDateCell = sheet.getCell('F2')
    aboveDateCell.value = 'Date:'

    const today = format(new Date(), 'eee, MMM do, yyyy')
    const dateCell = sheet.getCell('F3')
    dateCell.value = today
    dateCell.font = {
        size: 14,
        color: { argb: '444444' }
    }

    sheet.addRow(['']).commit()
    const row = sheet.addRow(['', quiz.title])
    row.font = {
        size: 18,
        bold: true
    }
    row.commit()
    const descRow = sheet.addRow(['', quiz.description])
    descRow.font = {
        size: 16,
        color: { argb: '444444' }
    }
    descRow.commit()

    sheet.addRow(['']).commit()

    // add columns
    const columns = [
        '#', 'Student', '', 'Email', 'Score', 'Date', '', '', ''
    ]
    sheet.addRow(columns).commit()
    sheet.columns[0].width = 4;
    sheet.columns[1].width = 40;
    sheet.columns[2].width = 20;
    sheet.columns[3].width = 20;
    sheet.columns[4].width = 12;
    sheet.columns[5].width = 30;

    sheet.columns[0].alignment = { horizontal: 'left' }

    sheet.getRow(8).height = 21;
    sheet.getRow(8).alignment = { vertical: 'middle' };

    ['A8', 'B8', 'C8', 'D8', 'E8', 'F8'].forEach((position: string) => {
        const cell = sheet.getCell(position)
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9D9D9' },
        }
        cell.font = {
            size: 16,
            bold: true
        }
        cell.border = {
            right: { style: 'thin', color: { argb: '000000' } },
            top: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        }
    })

    // sheet.getCell('E8').border = {
    //     left: { style: 'thin', color: { argb: '000000' } },
    //     top: { style: 'thin', color: { argb: '000000' } },
    //     bottom: { style: 'thin', color: { argb: '000000' } },
    //     right: { style: 'thin', color: { argb: '000000' } },
    // }

    for (const [i, response] of responses.entries()) {
        const row = sheet.addRow([
            i + 1,
            response.audience && response.audience.name ? response.audience?.name : response.profile.name,
            response.audience && response.audience.code ? response.audience.code : '',
            response.profile?.email ?? "-",
            response.score ?? "-",
            response.isCompleted ? format(response.createdAt, humanDateFormat) : '-'
        ])
        row.font = {
            size: 16
        }
        // row.height = 14

        row.getCell(3).font = { size: 14, color: { argb: '555555' } }
        row.getCell(4).font = { size: 14, color: { argb: '444444' } }
        row.getCell(6).font = { size: 12, color: { argb: '444444' } }

        row.getCell(3).alignment = { horizontal: 'left' }
        row.getCell(4).alignment = { horizontal: 'left' }
        row.getCell(5).alignment = { horizontal: 'left' }

        row.commit()
    }

    const buffer = await workbook.xlsx.writeBuffer()
    return buffer
}

const buildWorkbook = ({ sheetName }: { sheetName: string }) => {
    const now = new Date()

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(sheetName);
    workbook.creator = 'QuizBase.app'
    workbook.created = now
    workbook.modified = now

    return { workbook, sheet }
}

export const exportQuestions = async (questions: Question[], { title, description }: { title: string, description: string }) => {
    const { workbook, sheet } = buildWorkbook({ sheetName: 'Questions' })

    // add empty rows
    sheet.addRow(['']).commit()
    sheet.addRow(['']).commit()
    sheet.addRow(['']).commit()

    // add date to the right
    const aboveDateCell = sheet.getCell('F2')
    aboveDateCell.value = 'Date:'

    const today = format(new Date(), 'eee, MMM do, yyyy')
    const dateCell = sheet.getCell('F3')
    dateCell.value = today
    dateCell.font = {
        size: 14,
        color: { argb: '444444' }
    }

    sheet.addRow(['']).commit()
    const row = sheet.addRow(['', title])
    row.font = {
        size: 18,
        bold: true
    }
    row.commit()
    const descRow = sheet.addRow(['', description])
    descRow.font = {
        size: 16,
        color: { argb: '444444' }
    }
    descRow.commit()

    sheet.addRow(['']).commit()

    // add columns
    const columns = [
        '#', 'Question', 'Type', 'Correct Answer', 'Choice 1', 'Choice 2', 'Choice 3', 'Choice 4'
    ]
    sheet.addRow(columns).commit()
    sheet.columns[0].width = 4;
    sheet.columns[1].width = 40;
    sheet.columns[2].width = 10;
    sheet.columns[3].width = 20;
    sheet.columns[4].width = 20;
    sheet.columns[5].width = 20;
    sheet.columns[6].width = 20;
    sheet.columns[7].width = 20;

    sheet.columns[0].alignment = { horizontal: 'left' };

    ['A8', 'B8', 'C8', 'D8', 'E8', 'F8', 'G8', 'H8'].forEach((position: string) => {
        const cell = sheet.getCell(position)
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9D9D9' },
        }
        cell.font = {
            size: 16,
            bold: true
        }
        cell.border = {
            right: { style: 'thin', color: { argb: '000000' } },
            top: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        }
    })

    for (const [i, question] of questions.entries()) {
        const row = sheet.addRow([
            i + 1,
            question.question,
            question.type ?? "mcq",
            question.choices.find((c) => c.id === question.correct_answer_id)?.content,
            question.choices[0].content,
            question.choices[1].content,
            question.choices[2].content,
            question.choices[3].content,
        ])
        row.font = {
            size: 16
        }

        row.getCell(3).font = { size: 14, color: { argb: '111111' } }
        row.getCell(4).font = { size: 14, color: { argb: '444444' } }
        row.getCell(5).font = { size: 14, color: { argb: '444444' } }
        row.getCell(6).font = { size: 14, color: { argb: '444444' } }
        row.getCell(7).font = { size: 14, color: { argb: '444444' } }
        row.getCell(8).font = { size: 14, color: { argb: '444444' } }

        row.getCell(3).alignment = { horizontal: 'left' }
        row.getCell(4).alignment = { horizontal: 'left' }
        row.getCell(5).alignment = { horizontal: 'left' }

        row.commit()
    }

    const buffer = await workbook.xlsx.writeBuffer()
    return buffer
}

export const exportQuestionsTemplate = async () => {
    const { workbook, sheet } = buildWorkbook({ sheetName: 'Questions' })

    // add empty rows
    sheet.addRow(['']).commit()
    sheet.addRow(['']).commit()
    sheet.addRow(['']).commit()

    // add date to the right
    const aboveDateCell = sheet.getCell('F2')
    aboveDateCell.value = 'Date:'

    const today = format(new Date(), 'eee, MMM do, yyyy')
    const dateCell = sheet.getCell('F3')
    dateCell.value = today
    dateCell.font = {
        size: 14,
        color: { argb: '444444' }
    }

    sheet.addRow(['']).commit()
    const row = sheet.addRow(['', 'Questions Template'])
    row.font = {
        size: 18,
        bold: true
    }
    row.commit()
    const descRow = sheet.addRow(['', 'Fill in the template'])
    descRow.font = {
        size: 16,
        color: { argb: '444444' }
    }
    descRow.commit()

    sheet.addRow(['']).commit()

    // add columns
    const columns = [
        '#', 'Question', 'Type', 'Correct Answer', 'Choice 1', 'Choice 2', 'Choice 3', 'Choice 4'
    ]
    sheet.addRow(columns).commit()
    sheet.columns[0].width = 4;
    sheet.columns[1].width = 40;
    sheet.columns[2].width = 10;
    sheet.columns[3].width = 20;
    sheet.columns[4].width = 20;
    sheet.columns[5].width = 20;
    sheet.columns[6].width = 20;
    sheet.columns[7].width = 20;

    sheet.columns[0].alignment = { horizontal: 'left' };

    ['A8', 'B8', 'C8', 'D8', 'E8', 'F8', 'G8', 'H8'].forEach((position: string) => {
        const cell = sheet.getCell(position)
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9D9D9' },
        }
        cell.font = {
            size: 16,
            bold: true
        }
        cell.border = {
            right: { style: 'thin', color: { argb: '000000' } },
            top: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        }
    })

    const exampleRow = sheet.addRow([
        1,
        'What is the capital of Egypt? (example question)',
        "mcq",
        "Cairo",
        "Sohag",
        "Luxor",
        "Cairo",
        "Alexandria",
    ])
    exampleRow.font = {
        size: 16
    }

    exampleRow.getCell(3).font = { size: 14, color: { argb: '111111' } }
    exampleRow.getCell(4).font = { size: 14, color: { argb: '444444' } }
    exampleRow.getCell(5).font = { size: 14, color: { argb: '444444' } }
    exampleRow.getCell(6).font = { size: 14, color: { argb: '444444' } }
    exampleRow.getCell(7).font = { size: 14, color: { argb: '444444' } }
    exampleRow.getCell(8).font = { size: 14, color: { argb: '444444' } }

    exampleRow.getCell(3).alignment = { horizontal: 'left' }
    exampleRow.getCell(4).alignment = { horizontal: 'left' }
    exampleRow.getCell(5).alignment = { horizontal: 'left' }

    exampleRow.commit()

    const buffer = await workbook.xlsx.writeBuffer()
    return buffer
}

export const importQuestionsFromExcel = async (fileHandle: FileSystemFileHandle) => {
    const workbook = new ExcelJS.Workbook();
    const file = await fileHandle.getFile()
    await workbook.xlsx.load(await file.arrayBuffer());

    const ws = workbook.worksheets[0]
    const rows = ws.getRows(9, 50)
    const questions = []

    for (const row of rows || []) {
        const question = row.getCell(2).value
        if (!question) {
            break;
        }
        const type = row.getCell(3).value
        const correctAnswer = row.getCell(4).value?.toString()
        const choices = [
            row.getCell(5).value?.toString(),
            row.getCell(6).value?.toString(),
            row.getCell(7).value?.toString(),
            row.getCell(8).value?.toString(),
        ]

        questions.push({
            question,
            type,
            correctAnswer,
            choices
        })
    }

    return questions
}

function toEnglishNumber(strNum: string) {
    var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
    var en = '0123456789'.split('');
    strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, x => en[ar.indexOf(x)]);
    strNum = strNum.replace(/[^\d]/g, '');
    return strNum;
}


export const importStudentsFromExcel = async (fileHandle: FileSystemFileHandle) => {
    const workbook = new ExcelJS.Workbook();
    const file = await fileHandle.getFile()
    await workbook.xlsx.load(await file.arrayBuffer());

    const students = []


    for (const ws of workbook.worksheets) {
        const rows = ws.getRows(19, 48)
        for (const row of rows || []) {
            let code = row.getCell('S').value?.toString()
            if (!code) {
                break;
            }
            code = toEnglishNumber(code)
            const name = row.getCell('N').value?.toString()
            if (!name) {
                break;
            }
            students.push({
                code,
                name,
            })
        }
    }

    return students
}