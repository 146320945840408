import { Navigate, Outlet, useLocation } from "react-router";
import { SidebarProvider } from "@/components/ui/sidebar";
import { StudioSidebar } from "@/components/StudioSidebar";
import { useAuthStore } from "@/store/auth.store";
import WorkspaceGuard from "@/components/auth/WorkspaceGuard";
import { useBootstrapStore } from "@/store/bootstrap.store";
import { useDialogStore } from "@/store/dialog.store";

export default function StudioLayout() {
  const location = useLocation();
  const { user } = useAuthStore();
  const { isLoading } = useBootstrapStore();
  const dialogs = useDialogStore((state) => state.dialogs);

  if (isLoading) {
    return (
      <div className="container mx-auto py-24">
        Loading Studio {isLoading ? "true" : "false"}
      </div>
    );
  }

  if (!user) {
    return <Navigate to={`/login?next=${location.pathname}`} replace />;
  }

  return (
    <WorkspaceGuard>
      <SidebarProvider>
        <StudioSidebar />
        <main className="w-full max-w-screen overflow-hidden">
          <Outlet />
          {dialogs.map((dialog, index) => (
            <div key={index}>{dialog}</div>
          ))}
        </main>
      </SidebarProvider>
    </WorkspaceGuard>
  );
}
