import { useState, useEffect } from "react";
import { useSearchParams, NavLink } from "react-router";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { ChevronLeft, SearchIcon } from "lucide-react";
import Fuse from "fuse.js";
import { getAllArticles } from "../utils/markdownLoader";
import { ArticleMetadata } from "@/types/help-center";

export function SearchResults() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");
  const [searchResults, setSearchResults] = useState<ArticleMetadata[]>([]);
  const [fuse, setFuse] = useState<Fuse<ArticleMetadata> | null>(null);
  const [loading, setLoading] = useState(true);
  const [_, setArticles] = useState<ArticleMetadata[]>([]);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const loadedArticles = await getAllArticles();
        setArticles(loadedArticles);

        // Initialize Fuse.js for search
        const fuseInstance = new Fuse(loadedArticles, {
          keys: ["title", "content", "tags"],
          includeScore: true,
          threshold: 0.3,
        });
        setFuse(fuseInstance);
      } catch (error) {
        console.error("Error loading articles:", error);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, []);

  useEffect(() => {
    const query = searchParams.get("q");
    if (query) {
      setSearchQuery(query);
    }
  }, [searchParams]);

  useEffect(() => {
    if (fuse && searchQuery.trim()) {
      const results = fuse.search(searchQuery);
      setSearchResults(results.map(result => result.item));
    } else {
      setSearchResults([]);
    }
  }, [searchQuery, fuse]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      setSearchParams({ q: searchQuery });
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="mb-6">
        <NavLink to="/help">
          <Button variant="ghost" className="pl-0">
            <ChevronLeft className="mr-2 h-4 w-4" />
            Back to Help Center
          </Button>
        </NavLink>
      </div>

      <h1 className="text-2xl font-bold mb-6">Search Results</h1>

      <form onSubmit={handleSearch} className="relative mb-8">
        <Input
          type="text"
          placeholder="Search for help..."
          className="w-full py-6 pl-12 pr-4 text-lg"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <Button
          type="submit"
          className="absolute right-2 top-1/2 transform -translate-y-1/2"
        >
          Search
        </Button>
      </form>

      <div className="mb-4">
        <p className="text-muted-foreground">
          {loading ? "Searching..." : `${searchResults.length} results for "${searchQuery}"`}
        </p>
      </div>

      {loading ? (
        <div className="text-center p-8">
          <p>Loading articles...</p>
        </div>
      ) : searchResults.length === 0 ? (
        <div className="text-center p-8 bg-gray-50 rounded-lg">
          <p className="mb-2">No results found for "{searchQuery}"</p>
          <p className="text-muted-foreground">Try using different keywords or browse our help categories</p>
          <Button variant="outline" className="mt-4" asChild>
            <NavLink to="/help">Browse Help Categories</NavLink>
          </Button>
        </div>
      ) : (
        <div className="space-y-6">
          {searchResults.map(result => (
            <div key={result.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <NavLink to={`/help/article/${result.id}`} className="block">
                <h2 className="text-xl font-medium text-blue-600 hover:underline mb-2">{result.title}</h2>
                <p className="text-muted-foreground mb-3">{result.excerpt}</p>
                <div className="flex flex-wrap gap-2">
                  <Badge variant="outline" className="bg-blue-50">{result.category}</Badge>
                  {result.tags.slice(0, 3).map((tag: string) => (
                    <Badge key={tag} variant="secondary" className="text-xs">{tag}</Badge>
                  ))}
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
