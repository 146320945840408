import { Workspace } from "@/types/workspace";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface WorkspaceState {
  workspaces: Workspace[];
  currentWorkspace: number;
  setCurrentWorkspace: (index: number) => void;
  setWorkspaces: (workspaces: Workspace[]) => void;
  updateWorkspace(id: string, workspace: Workspace): void;
  clear: () => void;
}

export const useWorkspaceStore = create<WorkspaceState>()(
  devtools(
    persist(
      (set) => ({
        workspaces: [],
        currentWorkspace: -1,
        setCurrentWorkspace: (index: number) => {
          set({ currentWorkspace: index })
        },
        setWorkspaces: (workspaces: Workspace[]) => set({ workspaces }),
        updateWorkspace: async (id, workspace) => {
          // set local state
          set((state) => {
            const index = state.workspaces.findIndex((w) => w.id === id);
            state.workspaces[index] = workspace;
            return { workspaces: [...state.workspaces] };
          });
        },
        clear: () => {
          set({ workspaces: [], currentWorkspace: -1 });
        },
      }),
      { name: "workspaceStore" },
    ),
  ),
);
