import { NewQuestionRequest, Question } from "@/types/question";
import { ExclamationTriangleIcon, PlusIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { useLayoutEffect, useState, useCallback } from "react";
import { ArrowUpDownIcon, DownloadIcon, FileDownIcon, FileUpIcon } from "lucide-react";
import { exportQuestions, exportQuestionsTemplate, importQuestionsFromExcel } from "@/lib/excel";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import QuestionBuilder from "@/routes/studio/editor/questions/QuestionBuilder";
import { useAuthStore } from "@/store/auth.store";
import { useQuestionsStore } from "@/store/questions.store";
import { useIsMobile } from "@/hooks/use-mobile";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

export default function QuestionsEditor({ disabled }: { disabled: boolean }) {
    const userId = useAuthStore((state) => state.user)?.id!
    const isMobile = useIsMobile()
    const questionsStore = useQuestionsStore();
    // We want to scroll to the new question when it is created
    // so we track this flag useing useLayoutEffect to scroll into the correct
    // position using the quetion id.
    const [newQuestionCreated, setNewQuestionCreated] = useState<string | null>(null);

    useLayoutEffect(() => {
        if (newQuestionCreated) {
            const element = document.getElementById(newQuestionCreated);
            element?.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [newQuestionCreated])

    const handleQuestionChange = useCallback(async (question: Question) => {
        try {
            // Optimistically update UI
            // questionsStore.(prev => {
            //     const newQuestions = [...prev];
            //     newQuestions[index] = question;
            //     return newQuestions;
            // });

            await questionsStore.updateQuestion(question.id, question);
        } catch (error) {
            // Revert on error
            // setQuestions(prev => {
            //     const newQuestions = [...prev];
            //     newQuestions[index] = prev[index];
            //     return newQuestions;
            // });
            toast.error("Failed to update question");
        }
    }, []);

    const handleIndexChange = useCallback(async (index: number, direction: "up" | "down") => {
        try {
            // const otherQuestionIndex = direction === "up" ? index - 1 : index + 1;
            // const otherQuestion = questions[otherQuestionIndex];
            // const currentQuestion = questions[index];

            // // Optimistically update UI
            // setQuestions(prev => {
            //     const newQuestions = [...prev];
            //     otherQuestion.index = currentQuestion.index;
            //     currentQuestion.index = otherQuestionIndex;
            //     newQuestions[index] = otherQuestion;
            //     newQuestions[otherQuestionIndex] = currentQuestion;
            //     return newQuestions;
            // });

            await questionsStore.reorderQuestions(index, direction === "up" ? index - 1 : index + 1);
        } catch (error) {
            // Revert on error
            // setQuestions(prev => [...prev]);
            toast.error("Failed to update question order");
        }
    }, []);

    const handleNewQuestion = useCallback(async () => {
        try {
            const id = crypto.randomUUID();
            const choices = ["", "", "", ""].map((c) => ({
                id: crypto.randomUUID(),
                content: c,
            }));
            const newQuestion: NewQuestionRequest = {
                id,
                index: questionsStore.questions.length,
                type: "mcq",
                question: "",
                choices,
                correct_answer_id: choices[2].id,
                selected_bank_id: null,
                bank_id: questionsStore.bankId,
                quiz_id: questionsStore.quizId,
            }

            // Optimistically update UI
            // setQuestions(prev => [...prev, newQuestion]);
            setNewQuestionCreated(id);
            // onCountChange(questions.length + 1);

            await questionsStore.addQuestion(newQuestion);

            toast.success("Question created");
        } catch (error) {
            // Revert on error
            // setQuestions(prev => prev.slice(0, -1));
            // onCountChange(questions.length);
            toast.error("Failed to create new question");
        }
    }, [userId, questionsStore.quizId, questionsStore.bankId, questionsStore.questions.length]);

    const handleDeleteQuestion = useCallback(async (index: number) => {
        try {
            const deletedQuestion = questionsStore.questions[index];

            // Optimistically update UI
            // setQuestions(prev => prev.filter((_, i) => i !== index));
            // onCountChange(questions.length - 1);

            await questionsStore.deleteQuestion(deletedQuestion.id);

            toast.success("Question deleted");
        } catch (error) {
            // Revert on error
            // setQuestions(prev => [...prev]);
            // onCountChange(questions.length);
            toast.error("Failed to delete question");
            console.error(error);
        }
    }, [questionsStore.deleteQuestion, questionsStore.questions]);

    // FIXME
    const handleExport = async () => {
        toast.info('Your download will start shortly')
        const file = await exportQuestions(questionsStore.questions, { title: '', description: '' })
        const blob = new Blob([file], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `questions-QuizBase.xlsx`;
        a.click();
    }

    const handleDownloadImportTemplate = async () => {
        toast.info('Your download will start shortly')
        const file = await exportQuestionsTemplate()
        const blob = new Blob([file], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `QuizBase-questions-template.xlsx`;
        a.click();
    }

    const handleImportQuestions = async () => {
        try {
            const [fileHandle] = await window.showOpenFilePicker()
            if (fileHandle) {
                toast.info("We have recieved your file", {
                    description: "give us a moment to process it."
                })
                const importedQuestions = await importQuestionsFromExcel(fileHandle)
                const { error } = await questionsStore.importQuestions(importedQuestions);
                if (error) {
                    toast.error('failed to import file', {
                        dismissible: true
                    })
                }

                toast.success('Questions imported successfully', {
                    dismissible: true,
                    description: `${importedQuestions.length} questions have been added to the quiz`
                })
            }
        } catch (err) {
            console.error(err)
            toast.error('failed to import file', {
                dismissible: true
            })
        }
    }

    return (
        <section className="max-w-2xl flex-1 px-4 lg:px-8 mt-3">
            <div className="flex flex-row items-center gap-3">
                <div>
                    <h4 className="flex items-center gap-1 text-lg leading-7 font-bold text-gray-700">
                        Questions
                        <span className="text-muted-foreground text-xs">
                            ({questionsStore.questions.length})
                        </span>
                    </h4>
                    <p className="text-muted-foreground text-sm">Build your quiz questions</p>
                </div>
                <span className="flex-1" />
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="ml-auto bg-white" disabled={disabled}>
                            <ArrowUpDownIcon /> {isMobile ? "" : "Import/Export"}
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuItem onClick={handleExport}><DownloadIcon />Export questions</DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={handleImportQuestions}><FileUpIcon /> Import questions</DropdownMenuItem>
                        <DropdownMenuItem onClick={handleDownloadImportTemplate}><FileDownIcon /> Download import template</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>

                <Button
                    className="mr-6"
                    onClick={handleNewQuestion}
                    size="sm"
                    disabled={disabled}
                >
                    <PlusIcon />
                    {isMobile ? "Create" : "Add question"}
                </Button>
            </div>

            {disabled && (
                <div className="pr-6">
                    <Alert className="bg-orange-100/50 border-yellow-400 mt-4">
                        <ExclamationTriangleIcon className="h-4 w-4" />
                        <AlertTitle>Editing disabled</AlertTitle>
                        <AlertDescription>
                            This quiz has responses. You can no longer edit the questions.
                        </AlertDescription>
                    </Alert>
                </div>
            )}

            <div className="mt-6 flex w-full flex-col gap-4">
                {questionsStore.questions.map((q, i) => (
                    <QuestionBuilder
                        key={q.id}
                        question={q}
                        isFirstQuestion={i === 0}
                        isLastQuestion={i === questionsStore.questions.length - 1}
                        onQuestionChange={handleQuestionChange}
                        onIndexChange={handleIndexChange}
                        onDeleteQuestion={handleDeleteQuestion}
                        index={i}
                        disabled={disabled}
                    />
                ))}
                <Button
                    className="mr-6"
                    onClick={handleNewQuestion}
                    disabled={disabled}
                >
                    <PlusIcon />
                    Add question
                </Button>
            </div>
        </section>
    );
}
