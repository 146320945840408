import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

type Props = {
  id: string;
  name: string;
  description: string | null;
  logo: string | null;
};

export default function WorkspaceInfoCard({ name, description, logo }: Props) {
  return (
    <section id="organizationInfo" className="flex items-start gap-4">
      {logo && (
        <Avatar>
          <AvatarImage
            src={logo ?? "https://placehold.co/600x400?text=QB"}
            alt="logo"
          />
          <AvatarFallback>{name}</AvatarFallback>
        </Avatar>
      )}
      <div>
        <h4 className="text-neutral-800">{name}</h4>
        <p className="text-muted-foreground text-xs">{description}</p>
      </div>
    </section>
  );
}
