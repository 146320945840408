import PageShell from "@/components/PageShell";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { getQuestionBankById } from "@/services/bank.service";
import { QuestionServiceImpl } from "@/services/question.service";
import { QuestionBankWithMetadata } from "@/types/bank";
import QuestionsEditor from "@/studio/QuestionsEditor";
import { CardStackIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Question } from "@/types/question";
import { useQuestionsStore } from "@/store/questions.store";

export default function BankDetails() {
    const workspaceId = useCurrentWorkspaceId();
    const { bankId } = useParams<{ bankId: string }>()
    const [bank, setBank] = useState<QuestionBankWithMetadata & { questions: Question[] } | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const questionsStore = useQuestionsStore();


    // useEffect(() => {
    //     if (!bankId) return;
    //     questionsStore.setBankId(bankId);
    //     questionsStore.setQuestionService(new QuestionServiceImpl(bankId, null));
    //     questionsStore.refreshQuestions()
    // }, [bankId]);


    // load question bank details
    useEffect(() => {
        if (!bankId) return;
        const fetchBank = async () => {
            setLoading(true);
            setError(null);
            try {
                const { data: bank, error } = await getQuestionBankById(workspaceId!, bankId!);
                if (error) {
                    setError(error.message);
                    return;
                }
                setBank(bank);
                questionsStore.setBankId(bankId);
                questionsStore.setQuestionService(new QuestionServiceImpl(bankId, null));
                questionsStore.refreshQuestions()
            } catch (error) {
                setError("Failed to fetch bank");
            } finally {
                setLoading(false);
            }
        };
        fetchBank();
    }, [bankId, workspaceId]);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!bank || error) {
        return <div>{error}</div>;
    }

    return (
        <PageShell items={[
            { label: "Question Banks", href: '/studio/banks', icon: <CardStackIcon className="h-4 w-4" /> },
            { label: bank.title }
        ]} actions={[]}>
            <QuestionsEditor disabled={false} />
        </PageShell>
    )
}