import { supabase } from "@/lib/supabase"
import { QuestionBankWithMetadata } from "@/types/bank"
import { Question } from "@/types/question"

export const listQuestionBanks = async (workspaceId: string) => {
    return await supabase.from('question_banks')
        .select('id,title,description,created_at,updated_at,author_id,workspace_id,author:profiles(*),questionsCount:questions_bank_id_fkey(count)')
        .eq('workspace_id', workspaceId)
        .order('created_at', { ascending: false })
        .overrideTypes<QuestionBankWithMetadata[]>()
}

export const createQuestionBank = async (workspaceId: string, userId: string, title: string, { description }: { description: string | null }) => {
    if (!title) {
        return { data: null, error: { code: 400, message: "Title is required" } };
    }

    return await supabase.from('question_banks')
        .insert({ title, description, workspace_id: workspaceId, author_id: userId })
        .select('id')
        .single()
}

export const getQuestionBankById = async (workspaceId: string, bankId: string) => {
    // make sure to determine if the bank questions has been used in quizzes which students are taking
    // if the quiz is already taken, then we should not allow the bank to be edited or deleted
    return await supabase.from('question_banks')
        .select('id,title,description,created_at,author:profiles(*),questions:questions_bank_id_fkey(*),questionsCount:questions_bank_id_fkey(count)')
        .eq('id', bankId)
        .eq('workspace_id', workspaceId)
        .single()
        .overrideTypes<QuestionBankWithMetadata & {questions: Question[]}>()
}

export const updateBank = async (workspaceId: string, bankId: string, { title, description, questionsCount }: { title: string; description: string | null, questionsCount: number }) => {
    return await supabase.from('question_banks')
        .update({ title, description, questions_count: questionsCount })
        .eq('id', bankId)
        .eq('workspace_id', workspaceId)
        .select('id')
        .single()
}