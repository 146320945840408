import { useWorkspaceStore } from "@/store/workspace.store";

export const useCurrentWorkspace = () => {
  const workspaces = useWorkspaceStore();
  if (!workspaces.workspaces || workspaces.workspaces.length === 0) {
    return null;
  }
  return workspaces.workspaces[workspaces.currentWorkspace];
};

export const useCurrentWorkspaceId = () => {
  const workspaces = useWorkspaceStore();

  if (!workspaces.workspaces || workspaces.workspaces.length === 0) {
    return null;
  }

  return workspaces.workspaces[workspaces.currentWorkspace].id;
};
