import { User } from "@supabase/supabase-js";
import { supabase } from "../supabase";
import { Student } from "@/types/student";
import * as Sentry from "@sentry/react";

export const ensureUserProfile = async ({ user, userId }: { user: User, userId: string }) => {
    try {
        // First check if the profile already exists to avoid duplicate key errors
        const { data: existingProfile, error: checkError } = await supabase
            .from("profiles")
            .select("id")
            .eq("id", userId);

        // Only proceed with creation if the profile doesn't exist
        if (checkError || !existingProfile || existingProfile.length === 0) {
            // Prepare profile data
            const profileData = {
                id: userId,
                name: user?.user_metadata?.name ? user?.user_metadata?.name : user?.email?.split("@")[0],
                email: user?.email,
                avatar_url: user?.user_metadata?.name ? user?.user_metadata?.name : "https://api.dicebear.com/9.x/thumbs/webp?seed=user",
            };

            if (user?.user_metadata?.full_name) {
                profileData.name = user.user_metadata.full_name;
            }

            if (user?.user_metadata?.avatar_url) {
                profileData.avatar_url = user.user_metadata.avatar_url;
            }

            // Use upsert instead of insert to handle race conditions
            const { error: upsertError } = await supabase
                .from("profiles")
                .upsert(profileData, { onConflict: 'id' });

            if (upsertError) {
                Sentry.captureException(upsertError, {
                    extra: {
                        userId,
                        action: "profile_upsert",
                        profileData
                    },
                });
                throw upsertError;
            }
        }

        return true;
    } catch (error) {
        Sentry.captureException(error, {
            extra: {
                userId,
                action: "ensure_user_profile"
            },
        });
        throw error;
    }

    return true
}

/**
 * Check if a user is associated with an audience record for a specific workspace
 */
export const checkUserAudience = async (userId: string, workspaceId: string): Promise<Student | null> => {
    const { data: audience, error } = await supabase
        .from("audience")
        .select("*")
        .eq("profileId", userId)
        .eq("workspaceId", workspaceId)
        .single();

    if (error || !audience) {
        return null;
    }

    return audience;
};

/**
 * Validate an audience code and link it to the user if valid
 */
export const validateAndLinkAudienceCode = async (
    userId: string,
    audienceCode: string,
    workspaceId: string,
    quizId: string,
    quizMaxAttempts: number,
): Promise<{ success: boolean; audience?: Student; error?: string }> => {
    // Check if the audience code exists and is valid
    const { data: audienceData, error: audienceError } = await supabase
        .from("audience")
        .select("*")
        .eq("code", audienceCode)
        .eq("workspaceId", workspaceId)
        .single();

    if (audienceError || !audienceData) {
        return {
            success: false,
            error: "Invalid student code. Please check and try again."
        };
    }

    // Check if this audience has already attempted the quiz
    const { data: existingAttempts, error: attemptsError } = await supabase
        .from("responses")
        .select("id")
        .eq("audienceId", audienceData.id)
        .eq("profileId", userId).or('profileId.is.null')
        .eq("quizId", quizId);

    if (attemptsError) {
        return {
            success: false,
            error: "Error checking previous attempts. Please try again."
        };
    }

    if (existingAttempts && quizMaxAttempts != 0 && existingAttempts.length >= quizMaxAttempts) {
        return {
            success: false,
            error: "This code has already been used for this quiz."
        };
    }

    // Link the audience to the user
    const { data: updatedAudience, error: updateError } = await supabase
        .from("audience")
        .update({ profileId: userId })
        .eq("code", audienceCode)
        .eq("workspaceId", workspaceId)
        .select("*")
        .single();

    if (updateError || !updatedAudience) {
        return {
            success: false,
            error: "Error linking audience code to your account. Please try again."
        };
    }

    return {
        success: true,
        audience: updatedAudience
    };
};

// export const upsertAudience = async (user: User, workspaceId: string): Promise<Audience> => {
//     try {
//         // First check if the audience already exists
//         const { data: audience } = await supabase
//             .from("audience")
//             .select("*")
//             .eq("profileId", user.id)
//             .eq("workspaceId", workspaceId)
//             .single();

//         if (audience) {
//             return audience;
//         }

//         // Generate a unique ID for the audience record
//         const audienceId = crypto.randomUUID();

//         // Use upsert to avoid duplicate key errors
//         const { data: newAudience, error: upsertError } = await supabase
//             .from("audience")
//             .upsert({
//                 id: audienceId, // Use a composite key to avoid conflicts
//                 profileId: user.id,
//                 code: user.id,
//                 name: user.user_metadata?.name || user.email?.split("@")[0] || 'Anonymous',
//                 workspaceId,
//                 updatedAt: new Date(),
//             })
//             .select('*')
//             .single();

//         if (upsertError) {
//             Sentry.captureException(upsertError, {
//                 extra: {
//                     userId: user.id,
//                     workspaceId,
//                     action: "audience_upsert"
//                 },
//             });
//             throw upsertError;
//         }

//         return newAudience!;
//     } catch (error) {
//         Sentry.captureException(error, {
//             extra: {
//                 userId: user.id,
//                 workspaceId,
//                 action: "upsert_audience"
//             },
//         });
//         throw error;
//     }
// }
