import { supabase } from "@/lib/supabase";
import { QuizSession } from "@/types/session";
import { shuffleArray } from "@/lib/utils";
import QuizStartScreen from "@/routes/quiz/QuizStartScreen";
import FocusedView from "@/routes/session/FocusedView";
import { useEditorStore } from "@/store/editor.store";
import { Question } from "@/types/question";
import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";
import { QuizReviewContainer } from "@/routes/quiz/QuizScoreReview";
import { Button } from "@/components/ui/button";


export default function PreviewQuiz() {
    const { quiz } = useEditorStore()
    const [questions, setQuestions] = useState<Question[]>([]);
    const [session, setSession] = useState<QuizSession>();


    useEffect(() => {
        if (!quiz) return
        const fetchQuestions = async () => {
            const { data: questions, error } = await supabase.from('questions').select('*').eq('quiz_id', quiz.id).limit(50)
            if (error) {
                throw error
            }

            // resolve any bank questions
            const results = await Promise.all(questions.map(async (q) => {
                if (q.type === 'bank') {
                    const { data } = await supabase.from('questions').select('*').eq('bank_id', q.selected_bank_id!)
                    if (!data) return q
                    const bankQuestions = shuffleArray(data)
                    const j = Math.floor(Math.random() * data.length);
                    return bankQuestions[j]
                }

                return q
            }))

            setQuestions(results)
        }
        fetchQuestions()
    }, [quiz?.id])


    if (!quiz) {
        return <div>Loading...</div>;
    }

    const createPreviewSession = async (quizId: string) => {
        const session: QuizSession = {
            id: crypto.randomUUID(),
            quizId,
            elementsOrder: [],
            userId: 'preview',
            currentElementIndex: 0,
            questions: [],
            selectedChoices: [],
            state: 'not_started',
            reason: 'preview',
            score: 0,
            maxScore: questions.length,
            createdAt: new Date(),
            duration: 0,
            updatedAt: new Date(),
            quiz: quiz,
            isCompleted: null,
            audienceId: null,
        }

        if (quiz.config.shuffleQuestions) {
            session.elementsOrder = shuffleArray(questions).map(q => q.id)
        } else {
            session.elementsOrder = questions.map(q => q.id)
        }

        return session
    }

    const handleStart = async () => {
        const newSessoin = await createPreviewSession(quiz.id)
        setSession(newSessoin)
    }

    const handleQuestionSubmit = (qId: string, choiceIds: string[]) => {
        const s = session!
        s.selectedChoices.push({
            qId,
            choiceIds,
            duration: differenceInSeconds(new Date().getTime(), s.updatedAt.getTime())
        })
        s.updatedAt = new Date()
        s.currentElementIndex += 1

        if (s.currentElementIndex === s.elementsOrder.length) {
            // grade and finish session
            const score = s.selectedChoices.reduce((acc, curr) => {
                const question = questions.find(q => q.id === curr.qId)
                if (!question) return acc
                if (question.correct_answer_id === curr.choiceIds[0]) {
                    return acc + question.points
                }
                return acc
            }, 0)

            s.score = score
            s.updatedAt = new Date()
            s.isCompleted = new Date()
            s.duration = differenceInSeconds(new Date().getTime(), s.createdAt.getTime())
            s.state = 'completed'
        }

        setSession({ ...s })
    }

    const resetSession = () => {
        setSession(undefined)
    }

    if (!session) {
        return (<div className="max-w-sm lg:max-w-2xl mx-auto py-4">
            <QuizStartScreen quiz={quiz} onStart={handleStart} canStart={true} afterSchedule={false} />
        </div>)
    }

    if (session.state === 'completed') {
        return <div className="max-w-sm lg:max-w-2xl mx-auto mt-4 ">
            {/* <SessionEndScreen session={session} /> */}
            <QuizReviewContainer response={session} orderedQuestions={questions} />
            <Button className="mt-4 mx-auto block" size="lg" onClick={resetSession}>End Preview</Button>
        </div>
    }

    return <div className="max-w-sm lg:max-w-2xl mx-auto bg-white rounded mt-4 p-4 border">
        <FocusedView index={session.currentElementIndex} question={questions[session.currentElementIndex]} isLastElement={session.currentElementIndex === session.elementsOrder.length - 1} isLoading={false} onChange={handleQuestionSubmit} />
    </div>
}