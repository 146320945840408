import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import FluidView from "./FluidView";
import SessionEndScreen from "./SessionEndScreen";
import SessionTimeoutScreen from "./SessionTimeoutScreen";
import FocusedView from "./FocusedView";
import { QuizSession } from "@/types/session";
import { endSession, loadNextQuestion, loadSession, submitAnswer } from "@/lib/data/session.service";
import SessionLayout from "./SessionLayout";
import CountdownTimer, { CountdownTimerRef } from "./CountdownTimer";
import { differenceInSeconds } from "date-fns";
import { useBootstrapStore } from "@/store/bootstrap.store";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function QuizSessionPage() {
  const { isLoading: bootstrapLoading } = useBootstrapStore();
  const { quizId, sessionId } = useParams<{ quizId: string, sessionId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [session, setSession] = useState<QuizSession>();
  const [error, setError] = useState<string | null>(null);
  const timer = useRef<CountdownTimerRef>(null);

  useEffect(() => {
    if (bootstrapLoading) {
      return
    }
    const load = async () => {
      const data = await loadSession(quizId!, sessionId!);
      setSession(data);
    }

    load().then().catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [quizId, sessionId, bootstrapLoading]);


  const handleQuestionChange = useCallback(async (qId: string, choiceIds: string[]) => {
    if (!session) return;
    setIsSaving(true)
    // save user answer, update session, check if grading should be done
    let newSession = await submitAnswer(session, qId, choiceIds)
    // setSession(newSession)

    // check if session should marked as completed
    if (session.quiz.config.mode === 'focused') {

      if (newSession.currentElementIndex > newSession.elementsOrder.length - 1) {
        newSession = await endSession(newSession, 'completed')
      } else {
        // load new question
        newSession = await loadNextQuestion(session)
      }
      // check if timers needs to reset
      if (newSession.quiz.config.timer.mode === 'perQuestion') {
        timer.current?.reset(newSession.quiz.config.timer.seconds)
      }
    }
    setIsSaving(false)
    setSession(newSession)
  }, [session]);

  const handleTimerCompletion = useCallback(async () => {
    if (!session) return;

    switch (session.quiz.config.timer.mode) {
      case "perQuestion":
        await handleQuestionChange(session.questions[0].id, []);
        break;
      case "total": {
        timer.current?.clear();
        // end the quiz
        const newSession = await endSession(
          session,
          "completed",
          "timeout"
        );
        setSession({ ...newSession });
        break;
      }
      default:
        break;
    }
  }, [handleQuestionChange, session]);


  const handleSubmit = useCallback(async () => {
    if (!session) return;
    setIsSaving(true)
    const newSession = await endSession(session, 'completed')
    timer.current?.clear();
    setSession(newSession)
    setIsSaving(false)
  }, [session, timer]);


  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className="flex flex-col pt-48 lg:pt-40">
      <div className="container">
        <Card className="max-w-2xl mx-auto">
          <CardHeader>
            <CardTitle className="text-4xl">Not found</CardTitle>
          </CardHeader>
          <CardContent>
            <h4 className="text-lg leading-9">{error}</h4>
            <p className="text-gray-600">It looks like you are trying to access a session that doesn't exist, or you don't have enough permission to access.</p>
          </CardContent>
        </Card>
      </div>
    </div>
  }

  if (!session) {
    return <div>Session not found</div>
  }

  if (session.isCompleted && session.state === "timeout") {
    return (
      <SessionLayout workspace={session.quiz.workspace}>
        <SessionTimeoutScreen />
      </SessionLayout>
    )
  }

  if (session.isCompleted) {
    return (
      <SessionLayout workspace={session.quiz.workspace}>
        <SessionEndScreen session={session} />
      </SessionLayout>
    )
  }

  // Calculate the remaining time based on when the session was created or last updated
  const calculateRemainingTime = () => {
    if (!session.quiz.config.timer || session.quiz.config.timer.mode === "off") {
      return 0;
    }

    if (session.quiz.config.timer.mode === "total") {
      // For total timer mode, calculate elapsed time since session creation
      const totalSeconds = session.quiz.config.timer.seconds;
      const elapsedSeconds = differenceInSeconds(new Date(), new Date(session.createdAt));
      const remainingTime = Math.max(0, totalSeconds - elapsedSeconds);
      return remainingTime;
    } else {
      // For per-question timer, use the configured seconds
      return session.quiz.config.timer.seconds;
    }
  };

  return (
    <SessionLayout workspace={session.quiz.workspace} topRight={
      session.quiz.config.timer && session.quiz.config.timer.mode !== "off" && (
        <CountdownTimer
          initialTime={calculateRemainingTime()}
          ref={timer}
          onComplete={handleTimerCompletion}
        />
      )
    }>
      {session?.quiz.config.mode === 'fluid' && <FluidView title={session.quiz.title} questions={session.questions} onChange={handleQuestionChange} onSubmit={handleSubmit} />}
      {session?.quiz.config.mode === 'focused' && <FocusedView question={session.questions[0]} onChange={handleQuestionChange} index={session.currentElementIndex} isLastElement={session.elementsOrder.length - 1 === session.currentElementIndex} isLoading={isSaving} />}
    </SessionLayout>
  )

}