import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { ToggleGroup } from "@/components/ui/toggle-group";
import { importStudentsFromExcel } from "@/lib/excel";
import { Group } from "@/types/student";
import { cn } from "@/lib/utils";
import { ReloadIcon } from "@radix-ui/react-icons";
import { ToggleGroupItem } from "@radix-ui/react-toggle-group";
import { useState } from "react";

type Props = {
    isOpen: boolean,
    groups: Group[]
    setIsOpen: (open: boolean) => void,
    onSubmit: (students: { name: string, code: string }[], groupId: string | null | undefined) => void
}

export default function ImportStudentsDialog({ isOpen, groups, setIsOpen, onSubmit }: Props) {
    const [step, setStep] = useState(0)
    const [students, setStudents] = useState<{ name: string, code: string }[]>([])
    const [selectedGroup, setSelectedGroup] = useState<string | null>()
    const [isLoading, setIsLoading] = useState(false)

    const onOpenFile = async () => {
        const [fileHandle] = await window.showOpenFilePicker()
        if (!fileHandle) {
            return
        }
        const loadedData = await importStudentsFromExcel(fileHandle)
        if (loadedData) {
            setStudents(loadedData)
            setStep(1)
        }
    }

    const handleFinalSubmit = async () => {
        setIsLoading(true)
        await onSubmit(students, selectedGroup)
        setIsLoading(false)
    }

    const Step1 = () => {

        return <ScrollArea className="h-[24rem]">
            <table className="flex flex-col border">
                <thead className="divide-y">
                    <th className="divide-x divide-y">
                        <td className="w-[10rem] py-2 px-4">Code</td>
                        <td className="px-4 w-full">Name</td>
                    </th>
                </thead>
                <tbody className="divide-y">
                    {students.map((s) => (
                        <tr key={s.code} className="divide-x divide-y">
                            <td className="w-[10rem] py-1 px-4">{s.code}</td>
                            <td className="px-4 w-full">{s.name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ScrollArea>
    }

    // choose wich group to import students to
    const Step2 = () => {
        return (
            <div className="">
                <Separator />
                <p className="mt-4">Found <span className="font-bold">{students.length}</span> valid student records.</p>
                <h4 className="font-semibold leading-9 mt-4">Where to import students data?</h4>
                <p className="text-sm">Select a group to store student data into</p>

                <ToggleGroup type="single" className="flex justify-start items-start gap-3 py-3" onValueChange={(id) => setSelectedGroup(id)}>
                    <ToggleGroupItem value={''} className={cn("bg-zinc-100 px-4 py-2 rounded-lg border", !selectedGroup ? 'bg-zinc-700 text-white' : '')}>No group</ToggleGroupItem>
                    {groups.map((g) => (
                        <ToggleGroupItem key={g.id} value={g.id} aria-label={`Toggle group ${g.name}`} className={cn("bg-zinc-100 px-4 py-2 rounded-lg border", selectedGroup === g.id ? 'bg-zinc-700 text-white' : '')}>{g.name}</ToggleGroupItem>
                    ))}
                </ToggleGroup>
            </div>
        );
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Import</Button>
            </DialogTrigger>
            <DialogContent className="min-w-2xl">
                <DialogHeader>
                    <DialogTitle>Load students data from Excel </DialogTitle>
                    <DialogDescription>This wizzard will help you import your students data from an excel sheet</DialogDescription>
                </DialogHeader>


                {step === 0 && <Step0 />}
                {step === 1 && <Step1 />}
                {step === 2 && <Step2 />}


                <DialogFooter className="">
                    <DialogClose asChild><Button variant="secondary">Cancel</Button></DialogClose>
                    {step === 0 && <Button className="px-8" onClick={onOpenFile}>Select File</Button>}
                    {step === 1 && <Button className="px-8" onClick={() => setStep(2)}>Next</Button>}
                    {step === 2 && <Button className="px-8" onClick={handleFinalSubmit}>{isLoading ? <span><ReloadIcon className="animate-spin" /> importing ....</span> : 'Import'}</Button>}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

const Step0 = () => {
    return <>
        <p>The excel sheet should follow the following format:</p>
        <ol className="text-sm pl-4">
            <li>- Only name and code are extracted from the excel sheet</li>
            <li>- Data should start from line 19 and end at line 48</li>
            <li>- The excel can have more than 1 sheet that follows the format</li>
            <li>- The name starts at column N through R</li>
            <li>- Student ID/Code starts at column S through Z</li>
        </ol>


        <table className="table divide-x divide-y border">
            <thead className="divide-x divide-y">
                <tr className="divide-x divide-y bg-zinc-100 text-center text-zinc-400">
                    <td></td>
                    <td>N</td>
                    <td>O</td>
                    <td>Q</td>
                    <td>R</td>
                    <td>S</td>
                    <td>T</td>
                    <td>U</td>
                    <td>V</td>
                    <td>W</td>
                    <td>X</td>
                    <td>Y</td>
                    <td>Z</td>
                </tr>
            </thead>
            <tbody className="devide-x divide-y">
                <tr className="divide-x divide-y text-center font-semobild text-zinc-400 bg-zinc-50">
                    <td>18</td>
                    <td colSpan={4}>Name</td>
                    <td colSpan={8}>Code</td>
                </tr>
                <tr className="divide-x divide-y text-center">
                    <td>19</td>
                    <td colSpan={4}>Mohamed Ahmed</td>
                    <td colSpan={8}>123456789</td>
                </tr>
            </tbody>
        </table></>
}