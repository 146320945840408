import { create } from "zustand";

interface BootstrapState {
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
}

export const useBootstrapStore = create<BootstrapState>((set) => ({
  isLoading: true,
  setIsLoading: (v: boolean) => set({ isLoading: v }),
}));
