import { LapTimerIcon } from "@radix-ui/react-icons";
import { formatTime } from "@/lib/utils";
import {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";

interface CountdownTimerProps {
  initialTime: number;
  onComplete: () => void;
}

export interface CountdownTimerRef {
  reset: (newTime?: number) => void;
  clear: () => void;
}

const CountdownTimer = forwardRef<CountdownTimerRef, CountdownTimerProps>(
  ({ initialTime, onComplete }, ref) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const timerRef = useRef<number | null>(null);
    const startTimeRef = useRef<number>(Date.now());
    const remainingTimeRef = useRef<number>(initialTime);
    const isRunningRef = useRef<boolean>(false);

    // Function to stop the animation frame
    const stopTimer = useCallback(() => {
      if (timerRef.current !== null) {
        cancelAnimationFrame(timerRef.current);
        timerRef.current = null;
      }
      isRunningRef.current = false;
    }, []);

    // Function to update the timer using requestAnimationFrame
    const updateTimer = useCallback(() => {
      if (!isRunningRef.current) return;

      const now = Date.now();
      const elapsed = Math.floor((now - startTimeRef.current) / 1000);

      // Only update the state if the second has changed
      if (elapsed >= 1) {
        remainingTimeRef.current = Math.max(0, remainingTimeRef.current - elapsed);
        setTimeLeft(remainingTimeRef.current);
        startTimeRef.current = now;

        // Check if timer has completed
        if (remainingTimeRef.current <= 0) {
          stopTimer();
          return;
        }
      }

      // Continue the animation loop
      timerRef.current = requestAnimationFrame(updateTimer);
    }, [stopTimer]);

    // Function to start the timer
    const startTimer = useCallback(
      (newTime: number) => {
        stopTimer();
        remainingTimeRef.current = newTime;
        setTimeLeft(newTime);
        startTimeRef.current = Date.now();
        isRunningRef.current = true;
        timerRef.current = requestAnimationFrame(updateTimer);
      },
      [stopTimer, updateTimer],
    );

    // Start the timer initially
    useEffect(() => {
      startTimer(initialTime);
      return () => stopTimer();
    }, [initialTime, startTimer, stopTimer]);

    // Call onComplete when timer reaches zero
    useEffect(() => {
      if (timeLeft === 0) {
        onComplete();
      }
    }, [timeLeft, onComplete]);

    // Expose reset & clear functions to parent
    useImperativeHandle(ref, () => ({
      reset: (newTime = initialTime) => {
        startTimer(newTime);
      },
      clear: () => {
        stopTimer();
        setTimeLeft(0);
      },
    }));

    return (
      <div className="inline-flex items-center gap-2">
        <LapTimerIcon />
        <span className={timeLeft <= 5 ? "text-red-500" : "text-green-600"}>
          {formatTime(timeLeft)}
        </span>
      </div>
    );
  },
);

export default CountdownTimer;
