import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { Group } from "@/types/student";
import { DialogClose } from "@radix-ui/react-dialog";
import { toast } from "sonner";

type Props = {
    group: Group | null,
    onSubmit: ({ id, name }: { id: string | undefined, name: string }) => Promise<{ error: Error | null }>;
}

export default function GroupDialog2({ onSubmit, group }: Props) {
    const [editingGroup] = useState<boolean>(group ? true : false);
    const [newGroupName, setNewGroupName] = useState(group?.name ?? "");
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const handleSubmit = async () => {
        const { error } = await onSubmit({ id: group?.id, name: newGroupName });
        if (error) {
            toast.error("Failed to create/update group");
            console.error(error);
            return;
        }
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {editingGroup ? "Edit Group" : "Create New Group"}
                    </DialogTitle>
                </DialogHeader>
                <div className="space-y-4 py-4">
                    <div className="space-y-2">
                        <Label htmlFor="group-name">Group Name</Label>
                        <Input
                            id="group-name"
                            placeholder="Enter group name"
                            value={newGroupName}
                            onChange={(e) => { setNewGroupName(e.target.value) }}
                        />
                    </div>
                    <div className="flex justify-end gap-2">
                        <DialogClose asChild>
                            <Button variant="outline">Cancel</Button>
                        </DialogClose>
                        <Button onClick={handleSubmit}>
                            {editingGroup ? "Update" : "Create"}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}