import { DialogTitle, DialogDescription } from "@radix-ui/react-dialog";
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { ReactNode, useState } from "react";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";
import { buildQuizObject } from "@/lib/utils";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { useNavigate } from "react-router";
import { Quiz } from "@/types/quiz";

export default function QuizModal({ mode = 'create', quiz, children }: { mode?: 'create' | 'edit', quiz?: Quiz, children?: ReactNode}) {
  const workspaceId = useCurrentWorkspaceId();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(children ? false : true);
  const [title, setTitle] = useState(quiz?.title || "");
  const [description, setDescription] = useState(quiz?.description || "");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!title) {
      toast.error("Title is required");
      return;
    }

    if (mode === 'edit') {
      const { error } = await supabase
        .from("quizs")
        .update({title, description, updated_at: "now()"})
        .eq("id", quiz?.id)
        .single();
      if (error) {
        toast.error("Failed to updated quiz", {
          description: error.message,
        });
        return;
      }

      toast.success("Quiz updated successfully");
      setIsOpen(false);
      navigate(`/studio/quiz/${quiz?.id}`);
      return
    }


    const { data, error } = await supabase
      .from("quizs")
      .insert(buildQuizObject(title, description, workspaceId!))
      .select("id")
      .single();
    if (error) {
      console.error(error);
      toast.error("Failed to create quiz", {
        description: error.message,
      });
      return;
    }

    toast.success("Quiz created successfully");
    navigate(`/studio/quiz/${data?.id}`);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="font-xl font-bold">{mode === 'create' ? 'Create new Quiz' : 'Edit Quiz'}</DialogTitle>
          <DialogDescription>Type the details of your quiz</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Label>Title</Label>
            <Input
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              defaultValue={title}
              placeholder="Define whats your quiz about"
              autoComplete="off"
              autoFocus={true}
              tabIndex={0}
              className="bg-zinc-100"
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Description <span className="text-xs text-gray-500">(optional)</span></Label>
            <Input
              name="description"
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Summarize the goal of the quiz"
              autoComplete="off"
              tabIndex={0}
              className="bg-zinc-100"
            />
          </div>

          <div className="flex justify-end">
            <Button type="submit" className="w-min" tabIndex={0} size={"lg"}>
              { mode === 'create' ? 'Create' : 'Update' }
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
