import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Question } from "@/types/question";
import { QuestionBank } from "@/types/bank";
import { QuestionServiceImpl } from "@/services/question.service";
import { useEffect, useState } from "react";
import { Link } from "react-router";
import { ExternalLink } from "lucide-react";

type Props = {
    question: Question;
    questionBanks: QuestionBank[];
    disabled: boolean;
    onChange: (question: Question) => void;
}

export default function PullFromBankForm({ questionBanks, onChange, question, disabled }: Props) {
    const [errors] = useState<{ [field: string]: string }>({});
    const [selectedBankId, setSelectedBankId] = useState<string>(question.selected_bank_id || '');
    const [questions, setQuestions] = useState<Question[]>([]);

    useEffect(() => {
        if (selectedBankId) {
            const svc = new QuestionServiceImpl(selectedBankId, null);
            svc.load({ limit: 3 }).then((res) => {
                setQuestions(res.data || []);
            });
        } else {
            setQuestions([]);
        }
    }, [selectedBankId]);

    return <form
        id={question.id}
        className="mt-4 mb-6 px-6"
        onSubmit={(e) => e.preventDefault()}
    >
        <label className="mb-2 block text-sm font-bold text-gray-700 leading-none">
            Select Question Bank
        </label>
        <p className="text-muted-foreground text-sm">This will select a random question from the bank. The question will be picked for each student upon starting the quiz.</p>
        <Select
            value={selectedBankId}
            onValueChange={(value) => {
                setSelectedBankId(value);
                onChange({ ...question, selected_bank_id: value });
            }}
        >
            <SelectTrigger className="w-full mt-3" disabled={disabled}>
                <SelectValue placeholder="Select a question bank" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {questionBanks.map((bank) => (
                        <SelectItem key={bank.id} value={bank.id}>
                            {bank.title} ({bank.questionsCount[0].count} questions)
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>

        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                <p className="text-xs font-semibold text-muted-foreground mt-3 ml-3">Example questions</p>
                <Link to={`/studio/banks/${question.selected_bank_id}`} className="text-xs text-muted-foreground mt-3 flex items-center gap-2" target="_blank">
                    <ExternalLink className="w-3 h-3" /> View all
                </Link>
            </div>
            {questions.map((q) => (
                <div key={q.id} className="text-xs ml-4">
                    - {q.question}
                </div>
            ))}
        </div>
        {errors.bank && <p className="text-red-500">{errors.bank}</p>}
    </form>;
}