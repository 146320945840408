import { TimerIcon } from "@radix-ui/react-icons";

export default function SessionTimeoutScreen() {
  return (
    <div className="rounded-lg border bg-white p-8">
      <h1 className="mb-4 inline-flex items-center gap-2 text-3xl font-bold">
        <TimerIcon className="h-8 w-8 text-red-600" />
        You are out of time!
      </h1>
      <p className="text-muted-foreground px-12 text-lg">
        The choices you have selected has been saved.
      </p>
      <p className="mt-12 px-12 text-gray-700">
        You can safely close this page.
      </p>
    </div>
  );
}
