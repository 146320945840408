import { useState, useEffect } from "react";
import { useParams, Link } from "react-router";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { ChevronLeft, ThumbsUp, ThumbsDown, Clock } from "lucide-react";
import { format } from "date-fns";
import { getArticleById, getAllArticles } from "../utils/markdownLoader";
import { Article, ArticleMetadata } from "@/types/help-center";

export function ArticleViewer() {
  const { articleId } = useParams<{ articleId: string }>();
  const [article, setArticle] = useState<Article | null>(null);
  const [relatedArticles, setRelatedArticles] = useState<ArticleMetadata[]>([]);
  const [feedback, setFeedback] = useState<"helpful" | "not-helpful" | null>(null);
  const [allArticles, setAllArticles] = useState<ArticleMetadata[]>([]);

  // Load all articles once
  useEffect(() => {
    const loadAllArticles = async () => {
      const data = await getAllArticles();
      setAllArticles(data);
    };

    loadAllArticles();
  }, []);

  // Load specific article and related articles when articleId changes
  useEffect(() => {
    const loadArticle = async () => {
      if (articleId) {
        const foundArticle = await getArticleById(articleId);
        setArticle(foundArticle);

        if (foundArticle && allArticles.length > 0) {
          // Find related articles based on category and tags
          const related = allArticles
            .filter(a => a.id !== articleId &&
              (a.category === foundArticle.category ||
                a.tags.some(tag => foundArticle.tags.includes(tag))))
            .slice(0, 3);
          setRelatedArticles(related);
        }
      }
    };

    if (articleId) {
      loadArticle();
    }
  }, [articleId]);

  if (!article) {
    return <div className="p-8 text-center">Article not found</div>;
  }

  return (
    <div className="container">
      <div className="mb-6">
        <Link to="/help">
          <Button variant="ghost" className="pl-0">
            <ChevronLeft className="mr-2 h-4 w-4" />
            Back to Help Center
          </Button>
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="md:col-span-2">
          <div className="flex flex-col gap-4 mb-2">
            <Badge variant="outline" className="bg-blue-50">{article.category}</Badge>
            <div className="text-sm text-muted-foreground flex items-center gap-2">
              <Clock className="h-4 w-4" />
              Updated {format(new Date(article.updatedAt), "MMM d, yyyy")}
            </div>
          </div>

          <h1 className="text-3xl font-bold mb-4">{article.title}</h1>

          <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: article.content }} />

          <div className="mt-8 border-t pt-6">
            <h3 className="text-lg font-medium mb-3">Was this article helpful?</h3>
            <div className="flex gap-4">
              <Button
                variant={feedback === "helpful" ? "default" : "outline"}
                onClick={() => setFeedback("helpful")}
                className="flex items-center gap-2"
              >
                <ThumbsUp className="h-4 w-4" />
                Yes, it helped
              </Button>
              <Button
                variant={feedback === "not-helpful" ? "default" : "outline"}
                onClick={() => setFeedback("not-helpful")}
                className="flex items-center gap-2"
              >
                <ThumbsDown className="h-4 w-4" />
                No, I need more help
              </Button>
            </div>

            {feedback === "not-helpful" && (
              <div className="mt-4 p-4 bg-gray-50 rounded-md">
                <p className="mb-2">We're sorry this article didn't help. Please contact our support team for further assistance.</p>
                <Button variant="secondary">Contact Support</Button>
              </div>
            )}
          </div>
        </div>

        <div>
          <Card className="p-4 mb-6">
            <h3 className="font-medium mb-3">Table of Contents</h3>
            <ul className="space-y-2 text-sm">
              {article.toc.map((item: any, index: number) => (
                <li key={index}>
                  <a href={`#${item.id}`} className="text-blue-600 hover:underline">
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
          </Card>

          {relatedArticles.length > 0 && (
            <Card className="p-4">
              <h3 className="font-medium mb-3">Related Articles</h3>
              <ul className="space-y-3 text-sm">
                {relatedArticles.map(related => (
                  <li key={related.id}>
                    <Link to={`/help/article/${related.id}`} className="text-blue-600 hover:underline block">
                      {related.title}
                    </Link>
                    <p className="text-muted-foreground text-xs mt-1">{related.excerpt}</p>
                    <Separator className="mt-2" />
                  </li>
                ))}
              </ul>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}
