import { FormEvent, useState } from "react";
import { supabase } from "../lib/supabase";
import { Button } from "./ui/button";
import { useNavigate, useSearchParams } from "react-router";
import { Separator } from "./ui/separator";
import { toast } from "sonner";
import { UpdateIcon } from "@radix-ui/react-icons";

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const redirectTo = searchParams.has("next")
    ? import.meta.env.VITE_BASE_URL + searchParams.get("next")
    : import.meta.env.VITE_BASE_URL;
  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setIsLoading(false);
      toast.error("Failed to login", {
        description: error.message,
      });
      return;
    }
    setIsLoading(false);
    const destination = searchParams.get("next") || "/";
    navigate(destination);
  };

  const handleGoogleLogin = async () => {
    await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: redirectTo,
      },
    });
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50">
      <div className="w-full max-w-md space-y-8 rounded-lg bg-white p-8 shadow">
        <div>
          <h2 className="mt-3 text-center text-xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <Button
            onClick={handleGoogleLogin}
            className="w-full items-center gap-4"
          >
            <img
              src="/google.png"
              alt="Google"
              className="h-4 w-4 rounded-full"
            />
            Login with Google
          </Button>
          <Separator />
          <p className="text-muted-foreground text-center text-xs hidden">
            Sign using email and password
          </p>
          <form className="space-y-6 hidden" onSubmit={handleLogin}>
            <div className="flex flex-col gap-3 -space-y-px">
              <div>
                <label
                  htmlFor="email"
                  className="text-sm leading-9 text-gray-500"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="bg-zinc-100 relative block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none sm:text-sm"
                  placeholder="e.g: john@doe.com"
                  autoComplete="username"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="flex items-center text-sm leading-9 text-gray-500"
                >
                  Password
                  <span className="flex-1" />
                  <span className="text-muted-foreground cursor-not-allowed text-xs">
                    Forget your password?
                  </span>
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="bg-zinc-100 relative block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none sm:text-sm"
                  placeholder="*******"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className="w-full bg-neutral-900"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Sign in"
                ) : (
                  <>
                    <UpdateIcon className="spin" /> loading...
                  </>
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
