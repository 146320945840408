import { useState, useEffect } from "react";
import { useParams, Link } from "react-router";
import { helpCategories } from "../data/helpCategories";
import { Button } from "@/components/ui/button";
import { ArticleCard } from "./ArticleCard";
import { ChevronLeft } from "lucide-react";
import { getAllArticles } from "../utils/markdownLoader";
import { ArticleMetadata } from "@/types/help-center";

export function CategoryViewer() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [category, setCategory] = useState<any | null>(null);
  const [articles, setArticles] = useState<ArticleMetadata[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        if (categoryId) {
          const foundCategory = helpCategories.find(c => c.id === categoryId);
          setCategory(foundCategory || null);

          if (foundCategory) {
            const allArticles = await getAllArticles();
            const categoryArticles = allArticles.filter(a => a.category === foundCategory.title);
            setArticles(categoryArticles);
          }
        }
      } catch (error) {
        console.error("Error loading articles:", error);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, [categoryId]);

  if (!category) {
    return <div className="p-8 text-center">Category not found</div>;
  }

  return (
    <div className="max-w-5xl mx-auto p-4">
      <div className="mb-6">
        <Link to="/help">
          <Button variant="ghost" className="pl-0">
            <ChevronLeft className="mr-2 h-4 w-4" />
            Back to Help Center
          </Button>
        </Link>
      </div>

      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 rounded-full bg-blue-50">
          <category.icon className="h-6 w-6 text-blue-600" />
        </div>
        <h1 className="text-2xl font-bold">{category.title}</h1>
      </div>

      <p className="text-lg text-muted-foreground mb-8">{category.description}</p>

      {loading ? (
        <div className="text-center p-8">
          <p>Loading articles...</p>
        </div>
      ) : articles.length === 0 ? (
        <div className="text-center p-8 bg-gray-50 rounded-lg">
          <p>No articles found in this category.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {articles.map(article => (
            <ArticleCard
              key={article.id}
              id={article.id}
              title={article.title}
              excerpt={article.excerpt}
              category={article.category}
              tags={article.tags}
            />
          ))}
        </div>
      )}
    </div>
  );
}
