import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { NavLink } from "react-router";

export default function Error404() {
  return (
    <div className="container">
      <h1 className="py-4 text-2xl font-semibold">QuizBase</h1>
      <Card className="mx-auto mt-4 max-w-xl">
        <CardHeader>
          <CardTitle className="pt-6 text-2xl">Ooops!</CardTitle>
          <CardDescription className="text-xl">
            The page you are looking for doesn't exist!
          </CardDescription>
          <CardContent>
            <div className="mt-12 text-center">
              <Button asChild>
                <NavLink to="/">Go back home</NavLink>
              </Button>
            </div>
          </CardContent>
        </CardHeader>
      </Card>
    </div>
  );
}
