import { useEffect, useRef, useState } from "react";
import { Question } from "@/types/question";
import { QuestionBank } from "@/types/bank";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  Select, SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { listQuestionBanks } from "@/services/bank.service";
import MCQuestionForm from "./MCQuestionForm";
import PullFromBankForm from "./PullFromBankForm";

type Props = {
  question: Question;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  index: number;
  disabled: boolean;
  onIndexChange: (index: number, direction: "up" | "down") => void;
  onQuestionChange: (question: Question) => void;
  onDeleteQuestion: (index: number) => void;
};

export default function QuestionBuilder({
  question: initialValue,
  isFirstQuestion,
  isLastQuestion,
  index,
  disabled,
  onIndexChange,
  onQuestionChange,
  onDeleteQuestion,
}: Props) {
  const workspaceId = useCurrentWorkspaceId();
  const [question, setQuestion] = useState<Question>(initialValue);
  const [_, setErrors] = useState<{ [field: string]: string }>({});
  const initialRender = useRef(true);
  const [questionBanks, setQuestionBanks] = useState<QuestionBank[]>([]);

  // Load question banks on component mount
  useEffect(() => {
    if (workspaceId) {
      const fetchQuestionBanks = async () => {
        try {
          const { data, error } = await listQuestionBanks(workspaceId);
          if (error) {
            console.error("Error fetching question banks:", error);
            return;
          }
          setQuestionBanks(data || []);
        } catch (error) {
          console.error("Error fetching question banks:", error);
        }
      };
      fetchQuestionBanks();
    }
  }, [workspaceId]);

  // detect question changes
  useEffect(() => {
    // the question is changed on initial render, so we don't want to save it
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    setErrors({});
    // run validation
    if (question.type !== "bank") {
      if (!question.choices.length) {
        setErrors((state) => ({ ...state, choices: "Choices cannot be empty" }));
        return;
      }
      if (!question.question) {
        setErrors((state) => ({
          ...state,
          question: "Question cannot be empty",
        }));
        return;
      }
      if (!question.correct_answer_id) {
        setErrors((state) => ({ ...state, answer: "Must choose an answer" }));
        return;
      }
    } else {
      // For bank type questions
      if (!question.selected_bank_id) {
        setErrors((state) => ({ ...state, bank: "Must select a question bank" }));
        return;
      }
    }
    // save to db
    onQuestionChange(question);
  }, [index, onQuestionChange, question, workspaceId]);

  const handleQuestionChange = (q: Question) => {
    setQuestion({ ...q });
    onQuestionChange(q)
  };

  const handleDeleteQuestion = async (index: number) => {
    onDeleteQuestion(index);
  };

  return (
    <div className="flex gap-2">
      <section
        className="w-full max-w-2xl rounded-lg border bg-white"
      >
        <div className="flex gap-4 border-b px-6 py-2 items-center">
          <div className="font-semibold">
            Q. <span>{index + 1}</span>
          </div>
          <span className="flex-1" />
          <Select
            disabled={disabled}
            name="type"
            value={question.type}
            onValueChange={(newType: "mcq" | "truefalse" | "bank") => {
              let updatedQuestion: Question;

              if (newType === "truefalse") {
                // Create True/False question with two fixed choices
                updatedQuestion = {
                  ...question,
                  type: newType,
                  choices: [
                    { id: crypto.randomUUID(), content: "True" },
                    { id: crypto.randomUUID(), content: "False" }
                  ],
                  correct_answer_id: "", // Reset answer
                };
                // setCorrectAnswerIndex(0);
              } else if (newType === "bank") {
                // Create question from bank
                updatedQuestion = {
                  ...question,
                  type: newType,
                  selected_bank_id: question.selected_bank_id,
                };
              } else {
                // Default MCQ question
                if (question.type === "bank") {
                  // If switching from bank to MCQ, ensure we have choices
                  updatedQuestion = {
                    ...question,
                    type: newType,
                    choices: [
                      { id: crypto.randomUUID(), content: "" },
                      { id: crypto.randomUUID(), content: "" },
                      { id: crypto.randomUUID(), content: "" },
                      { id: crypto.randomUUID(), content: "" }
                    ],
                    correct_answer_id: "", // Reset answer
                  };
                  // setCorrectAnswerIndex(-1);
                } else {
                  updatedQuestion = {
                    ...question,
                    type: newType,
                  };
                }
              }

              setQuestion(updatedQuestion);
            }}
          >
            <SelectTrigger disabled={disabled} className="bg-zinc-50/50 shadow-none border-dashed border border-zinc-300 max-w-[168px] rounded-md">
              <SelectValue placeholder="Select type" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="mcq">Multiple Choice</SelectItem>
                <SelectItem value="truefalse">True/False</SelectItem>
                <SelectItem value="bank">Use Question Bank</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant={"outline"}
                className="hover:text-red-500"
                name="delete"
                size={"icon"}
                disabled={disabled}
              >
                <TrashIcon />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This will delete the question and all its choices.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  className="bg-red-500"
                  onClick={() => handleDeleteQuestion(index)}
                >
                  Confirm
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>

        {question.type === "bank" && <PullFromBankForm questionBanks={questionBanks} onChange={handleQuestionChange} question={question} disabled={disabled} />}
        {(question.type === 'mcq' || question.type === 'truefalse') && <MCQuestionForm question={question} onChange={handleQuestionChange} disabled={disabled} />}

      </section>
      <div className="flex flex-col gap-2">
        <button
          disabled={isFirstQuestion || disabled}
          className={`${isFirstQuestion || disabled ? "text-gray-300" : ""}`}
          onClick={() => onIndexChange(index, "up")} 
        >
          <ChevronUpIcon />
        </button>
        <button
          disabled={isLastQuestion || disabled}
          onClick={() => onIndexChange(index, "down")}
          className={`${isLastQuestion || disabled ? "text-gray-300" : ""}`}
        >
          <ChevronDownIcon />
        </button>
      </div>
    </div>
  );
}
