import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { ChevronRightIcon } from "lucide-react";
import { NavLink } from "react-router";

interface ArticleCardProps {
  id: string;
  title: string;
  excerpt: string;
  category: string;
  tags?: string[];
}

export function ArticleCard({ id, title, excerpt, category, tags = [] }: ArticleCardProps) {
  return (
    <NavLink to={`/help/article/${id}`}>
      <Card className="hover:shadow-md transition-shadow cursor-pointer h-full">
        <CardHeader className="pb-2">
          <div className="flex justify-between items-start">
            <CardTitle className="text-lg">{title}</CardTitle>
            <ChevronRightIcon className="h-5 w-5 text-muted-foreground" />
          </div>
          <CardDescription>{excerpt}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap gap-2 mt-2">
            <Badge variant="outline" className="bg-blue-50">{category}</Badge>
            {tags.slice(0, 2).map(tag => (
              <Badge key={tag} variant="secondary" className="text-xs">{tag}</Badge>
            ))}
            {tags.length > 2 && (
              <Badge variant="secondary" className="text-xs">+{tags.length - 2} more</Badge>
            )}
          </div>
        </CardContent>
      </Card>
    </NavLink>
  );
}
