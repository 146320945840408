import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { SearchIcon } from "lucide-react";
import { useNavigate } from "react-router";
import Fuse from "fuse.js";
import { getAllArticles } from "../utils/markdownLoader";
import { ArticleMetadata } from "@/types/help-center";

export function HelpSearch() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<ArticleMetadata[]>([]);
  const [fuse, setFuse] = useState<Fuse<ArticleMetadata> | null>(null);
  const [loading, setLoading] = useState(true);
  const [_, setArticles] = useState<ArticleMetadata[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const loadedArticles = await getAllArticles();
        setArticles(loadedArticles);

        // Initialize Fuse.js for search
        const fuseInstance = new Fuse(loadedArticles, {
          keys: ["title", "excerpt", "tags"],
          includeScore: true,
          threshold: 0.3,
        });
        setFuse(fuseInstance);
      } catch (error) {
        console.error("Error loading articles:", error);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, []);

  useEffect(() => {
    if (fuse && searchQuery.trim()) {
      const results = fuse.search(searchQuery);
      setSearchResults(results.map(result => result.item));
    } else {
      setSearchResults([]);
    }
  }, [searchQuery, fuse]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/help/search?q=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto bg-white">
      <form onSubmit={handleSearch} className="relative">
        <Input
          type="text"
          placeholder="Search for help..."
          className="w-full py-6 pl-12 pr-4 text-lg"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <Button
          type="submit"
          className="absolute right-2 top-1/2 transform -translate-y-1/2"
          variant="ghost"
        >
          Search
        </Button>
      </form>

      {!loading && searchResults.length > 0 && searchQuery.trim() && (
        <div className="absolute z-10 w-full max-w-3xl bg-white shadow-lg rounded-md mt-1 max-h-96 overflow-y-auto">
          {searchResults.slice(0, 5).map((result) => (
            <div
              key={result.id}
              className="p-3 hover:bg-gray-100 cursor-pointer border-b"
              onClick={() => {
                navigate(`/help/article/${result.id}`);
                setSearchQuery("");
              }}
            >
              <h3 className="font-medium">{result.title}</h3>
              <p className="text-sm text-gray-500 truncate">{result.excerpt}</p>
            </div>
          ))}
          {searchResults.length > 5 && (
            <div
              className="p-3 text-center text-blue-600 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate(`/help/search?q=${encodeURIComponent(searchQuery)}`)}
            >
              See all {searchResults.length} results
            </div>
          )}
        </div>
      )}
    </div>
  );
}
