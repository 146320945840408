import { supabase } from "@/lib/supabase";
import { useAuthStore } from "@/store/auth.store";
import { useWorkspaceStore } from "@/store/workspace.store";
import StudioStartPage from "@/routes/studio/StartPage";
import { ReactNode, useEffect, useState } from "react";

export default function WorkspaceGuard({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthStore((state) => state.user);
  const { workspaces, setWorkspaces, setCurrentWorkspace } =
    useWorkspaceStore();

  useEffect(() => {
    const loadWorkspaces = async (userId: string) => {
      const { data, error } = await supabase
        .from("workspace_users")
        .select("workspaces(*)")
        .eq("userId", userId);

      if (error) {
        console.error(error);
        return;
      }
      
      if (data.length) {
        const workspaces = data.flatMap((w) => w.workspaces);
        // const workspaces = data.map(
        //   (w: { workspaces: Workspace[] }) => w.workspaces,
        // );
        setWorkspaces(workspaces);
        if (workspaces.length > 0) {
          setCurrentWorkspace(0);
        }
      }
    };
    if (!workspaces || !workspaces.length) {
      loadWorkspaces(user?.id ?? "").finally(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [setCurrentWorkspace, setWorkspaces, user?.id]);

  if (isLoading) {
    return <div>Loading workspace...</div>;
  }

  if (!workspaces.length) {
    return <StudioStartPage />;
  }

  return <>{children}</>;
}
