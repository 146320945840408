import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { supabase } from "@/lib/supabase";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts"
import { useEditorStore } from "@/store/editor.store";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { formatDuration } from "@/lib/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export default function QuizInsights() {
  const { quizId } = useParams<{ quizId: string }>();
  const { quiz } = useEditorStore();
  const [averageScore, setAverageScore] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [grades, setGrades] = useState([
    { grade: 'A', students: 0, percentage: 0, fill: '' },
    { grade: 'B', students: 0, percentage: 0, fill: '' },
    { grade: 'C', students: 0, percentage: 0, fill: '' },
    { grade: 'D', students: 0, percentage: 0, fill: '' },
    { grade: 'F', students: 0, percentage: 0, fill: '' },
  ])
  const [timeoutCount, setTimeoutCount] = useState(0)
  const [timeoutPercentage, setTimeoutPercentage] = useState(0) // number of responses that timed out
  const [fCount, setfCount] = useState(0) // number of students who got F
  const [failurePercentage, setFailurePercentage] = useState(0) // number of responses that failed
  const [averageDuration, setAverageDuration] = useState('') // average duration of responses
  const [totalStudents, setTotalStudents] = useState(0) // total number of responses

  useEffect(() => {
    const analyzeScores = (responses: { reason: string, score: number, duration: number }[]) => {
      if (!quiz) {
        return
      }
      const total = responses.length
      const totalDuration = responses.reduce((prev, current) => prev + current.duration, 0);
      const _averageDuration = totalDuration / responses.length;
      setAverageDuration(formatDuration(_averageDuration));

      const grades = {
        A: 0,
        B: 0,
        C: 0,
        D: 0,
        F: 0
      }
      const maxScore = quiz?.questionsCount[0].count;
      let failures = 0
      let timeouts = 0

      responses.forEach(resp => {
        // calculate grades
        const percent = (resp.score / maxScore) * 100;
        if (percent >= 90) grades.A++;
        else if (percent >= 75) grades.B++;
        else if (percent >= 65) grades.C++;
        else if (percent >= 60) grades.D++;
        else grades.F++;

        // calculate timeout percentage
        if (resp.reason === 'timeout') timeouts++;
        // calculate failure percentage
        if (resp.score < (maxScore * 0.6)) failures++;
      });

      const gradeData = [{ grade: 'A', students: grades.A, percentage: Number((grades.A / total * 100).toFixed(2)), fill: '#3F7D58' },
      { grade: 'B', students: grades.B, percentage: Number((grades.B / total * 100).toFixed(2)), fill: '#2563EB' },
      { grade: 'C', students: grades.C, percentage: Number((grades.C / total * 100).toFixed(2)), fill: '#9333EA' },
      { grade: 'D', students: grades.D, percentage: Number((grades.D / total * 100).toFixed(2)), fill: '#EF9651' },
      { grade: 'F', students: grades.F, percentage: Number((grades.F / total * 100).toFixed(2)), fill: '#EF4444' },
      ].map((g) => ({ ...g, percentage: isNaN(g.percentage) ? 0 : g.percentage }))

      setGrades(gradeData);
      setTimeoutCount(timeouts)
      const timeoutPercentage = Number((timeouts / (responses.length * 100)).toFixed(2))
      setTimeoutPercentage(isNaN(timeoutPercentage) ? 0 : timeoutPercentage);
      setfCount(failures)
      const failurePercentage = Number((failures / responses.length * 100).toFixed(2))
      setFailurePercentage(isNaN(failurePercentage) ? 0 : failurePercentage);
    };
    const load = async () => {
      // calculate average score
      const { data: avgScore } = await supabase.rpc("avgquizscore", {
        quizid: quizId,
      });
      if (avgScore) setAverageScore(avgScore);
      setIsLoading(false);

      // fetch responses
      const { data: responses } = await supabase
        .from("responses")
        .select("reason, score, duration")
        .eq("quizId", quizId)

      // analyze scores
      setTotalStudents(responses?.length || 0)
      analyzeScores(responses || []);
    };

    load();
  }, [quizId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col px-3">
      <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 mt-4 space-x-3 space-y-4">
        <Card className="">
          <CardContent className="py-4">
            <h4 className="text-sm font-semibold">Average Score</h4>
            <p className="text-xs text-muted-foreground">Average score achieved by students</p>
            <span className="text-4xl font-semibold mt-6 block text-green-900 text-right">{averageScore}</span>
          </CardContent>
        </Card>
        <Card className="bg-sky-100">
          <CardContent className="py-4">
            <h4 className="text-sm font-semibold">Average Duratoin</h4>
            <p className="text-xs text-muted-foreground">Time spent to complete the quiz</p>
            <span className="text-4xl font-semibold mt-8 block text-blue-800 text-right">{averageDuration}</span>
          </CardContent>
        </Card>
        <Card className="bg-orange-100">
          <CardContent className="py-4">
            <h4 className="text-sm font-semibold">Timeouts <span className="text-muted-foreground font-medium">({timeoutCount})</span></h4>
            <p className="text-xs text-muted-foreground">Percentage of students who couldn't complete the quiz</p>
            <span className="text-4xl font-semibold mt-8 block text-red-800 text-right">{timeoutPercentage}%</span>
          </CardContent>
        </Card>
        <Card className="bg-red-100">
          <CardContent className="pt-4">
            <h4 className="text-sm font-semibold">Failure Rate <span className="text-muted-foreground font-medium">({fCount})</span></h4>
            <p className="text-xs text-muted-foreground">Percentage of students who failed</p>
            <span className="text-4xl font-semibold mt-8 block text-red-800 text-right">{failurePercentage}%</span>
          </CardContent>
        </Card>
        <GradesBarChart grades={grades} />
        <div className="rounded border p-4 col-span-2 bg-white">
          <h3 className="mb-4 text-lg font-semibold">Grade Distribution</h3>
          <Table className="bg-white">
            <TableHeader>
              <TableRow>
                <TableHead>Grade</TableHead>
                <TableHead className="text-right">Students</TableHead>
                <TableHead className="text-right">Percentage</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {grades.map((grade) => {
                return (
                  <TableRow key={grade.grade}>
                    <TableCell>{grade.grade}</TableCell>
                    <TableCell className="text-right">{grade.students}</TableCell>
                    <TableCell className="text-right">{grade.percentage}%</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell className="text-right">{totalStudents}</TableCell>
                <TableCell className="text-right">100%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

const GradesBarChart = ({ grades }: { grades: any[] }) => {
  const config: ChartConfig = {}

  return (
    <Card className="col-span-2">
      <CardHeader>
        <CardTitle>Grades</CardTitle>
        <CardDescription>Grades distribution</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={config} className="min-h-[100px] w-full">
          <BarChart
            accessibilityLayer data={grades}>
            <CartesianGrid vertical={false} />
            <ChartTooltip cursor={false} content={<ChartTooltipContent nameKey="grade" hideLabel={true} indicator="dot" />} />
            <XAxis
              dataKey="grade"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <Bar
              dataKey="percentage"
              type="natural"
              fillOpacity={0.8}
            />
            <LabelList dataKey={"grade"} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card >);
}