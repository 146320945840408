import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

export default function UserAvatar({
  name,
  url,
  size,
}: {
  name: string;
  url?: string;
  size?: "sm" | "md" | "lg";
}) {
  const avatar = url ? url : `https://placehold.co/32x32?text=${name[0]}`;

  return (
    <Avatar className={`${size === "sm" ? "size-6" : size === "lg" ? "size-32" : "size-12"}`}>
      <AvatarImage src={avatar} alt={name} />
      <AvatarFallback>{name}</AvatarFallback>
    </Avatar>
  );
}
