import React from "react";
import type { ReactNode } from "react";
import { Link } from "react-router";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import { HomeIcon } from "@radix-ui/react-icons";
import { SidebarTrigger } from "@/components/ui/sidebar";

interface BreadcrumbsProps {
    items: {
        label: string;
        href?: string;
        icon?: ReactNode;
    }[];
    children?: ReactNode;
}

export function StudioBreadcrumbs({ items, children }: BreadcrumbsProps) {

    return (
        <div className="flex items-center gap-2 px-3 py-2 bg-white border-b max-w-full overflow-hidden">
            <SidebarTrigger className="mr-2" />

            <Breadcrumb className="flex w-full">
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <BreadcrumbLink asChild>
                            <Link to={`/studio`}>
                                <HomeIcon className="h-4 w-4" />
                            </Link>
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbSeparator />

                    {items.map((item, index) => {
                        const isLast = index === items.length - 1;

                        return (
                            <React.Fragment key={index}>
                                <BreadcrumbItem>
                                    {isLast ? (
                                        <BreadcrumbPage className="flex items-center gap-1">
                                            {item.icon && <span>{item.icon}</span>}
                                            {item.label}
                                        </BreadcrumbPage>
                                    ) : (
                                        <BreadcrumbLink asChild className="flex items-center gap-1">
                                            <Link to={item.href || "#"}>
                                                {item.icon && <span>{item.icon}</span>}
                                                {item.label}
                                            </Link>
                                        </BreadcrumbLink>
                                    )}
                                </BreadcrumbItem>

                                {!isLast && <BreadcrumbSeparator />}
                            </React.Fragment>
                        );
                    })}
                </BreadcrumbList>
                <span className="flex-1" />
                {children}
            </Breadcrumb>
        </div>
    );
}
