import { useIsSmallScreen } from "@/hooks/use-mobile";
import { useState } from "react";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { cn } from "@/lib/utils";

export const DateFilter = ({
    onChange,
  }: {
    onChange: (d: Date | undefined) => void;
  }) => {
    const [date, setDate] = useState<Date>();
    const isSmallScreen = useIsSmallScreen();
  
    const handleDateChange = (d: Date | undefined) => {
      setDate(d);
      onChange(d);
    };
  
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "bg-white",
              "lg:min-w-xs max-w-1/4 justify-start text-left font-normal",
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP") : <span>{isSmallScreen ? "Date" : "Filter by creation date"}</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={handleDateChange}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    );
  };
  