import { StudioBreadcrumbs } from "@/studio/StudioBreadcrumbs";

type Props = {
  items: { label: string; href?: string; icon?: React.ReactNode }[];
  actions: React.ReactNode;
  children: React.ReactNode;
};
export default function PageShell(props: Props) {

  return (
    <div className="flex flex-col max-w-full overflow-hidden">
      <StudioBreadcrumbs
        items={props.items ?? []}
      >
        {props.actions}
      </StudioBreadcrumbs>
      <div className="flex flex-col gap-4 py-4">{props.children}</div>
    </div>
  );
}
