import { useState } from "react";
import { toast } from "sonner";
import { useNavigate } from "react-router";
import { PlusIcon } from "@radix-ui/react-icons";
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { createQuestionBank } from "@/services/bank.service";
import { useAuthStore } from "@/store/auth.store";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";

export default function QuestionBankModal() {
    const navigate = useNavigate();
    const userId = useAuthStore(state => state.user?.id)
    const workspaceId = useCurrentWorkspaceId();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { data, error } = await createQuestionBank(workspaceId!, userId!, title, { description })
        if (error) {
            toast.error("Failed to create bank", {
                description: error.message,
            });
            return;
        }

        toast.success("bank created successfully");
        navigate(`/studio/banks/${data?.id}`);
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button size={"sm"} className="text-xs items-center">
                    <PlusIcon className="h-3 w-3" />
                    Create Question Bank
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="font-xl font-bold">Create new Bank</DialogTitle>
                    <DialogDescription>Type the details of your bank</DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                        <Label>Title</Label>
                        <Input
                            name="title"
                            onChange={(e) => setTitle(e.target.value)}
                            defaultValue={title}
                            placeholder="Define whats your quiz about"
                            autoComplete="off"
                            autoFocus={true}
                            tabIndex={0}
                            className="bg-zinc-100"
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <Label>Description</Label>
                        <Input
                            name="description"
                            defaultValue={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Summarize the goal of the quiz"
                            autoComplete="off"
                            tabIndex={0}
                            className="bg-zinc-100"
                        />
                    </div>

                    <div className="flex justify-end">
                        <Button type="submit" className="w-min" tabIndex={0}>
                            Create
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}
