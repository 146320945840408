import { Label } from "./ui/label";
import { Switch } from "./ui/switch";

type Props = {
  label: string;
  description: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (v: boolean) => void;
  children?: React.ReactNode;
};

export default function SwitchBox({
  label,
  description,
  checked,
  disabled,
  onChange,
  children
}: Props) {
  return (
    <div className="flex flex-col rounded-lg border bg-white px-4 py-2">
      <div className="flex items-center">
        <div className="">
          <Label className="font-semibold">{label}</Label>
          <p className="text-sm text-neutral-500">{description}</p>
        </div>
        <span className="flex-1" />
        <Switch
          name="protected"
          checked={checked}
          disabled={disabled}
          onCheckedChange={onChange}
        />
      </div>
      {children}
    </div>
  );
}
