import PageShell from "@/components/PageShell";
import { Button } from "@/components/ui/button";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { supabase } from "@/lib/supabase";
import { PersonIcon, PlusIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { TeamDataTable } from "./TeamDataTable";
import { teamColumns, User } from "./TeamColumns";

export default function TeamIndex() {
  const workspaceId = useCurrentWorkspaceId();

  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data, error } = await supabase
        .from("workspace_users")
        .select(`id:userId, role, profile:profiles(*)`)
        .eq("workspaceId", workspaceId!)
        .returns<User[]>();

      if (error) {
        toast.error("Failed to fetch users");
        console.error(error);
        return;
      }

      setUsers(data);
      setIsLoading(false);
    };

    fetchUsers();
  }, [workspaceId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <PageShell items={[
      { label: "Team Members", icon: <PersonIcon className="h-4 w-4" /> }
    ]} actions={undefined}>
      <div className="px-4 lg:px-8">
        <div className="flex">
          <span className="flex-1" />
          <Button>
            <PlusIcon /> Invite user
          </Button>
        </div>

        <TeamDataTable data={users} columns={teamColumns} />
      </div>
    </PageShell>
  );
}
