import SwitchBox from "@/components/SwitchBox";
import { supabase } from "@/lib/supabase";
import { useEditorStore } from "@/store/editor.store";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";

export default function QuizResultsSettings() {
  const { quiz, setQuiz } = useEditorStore();

  if (!quiz) {
    return
  }

  const handleRevealFinalScoreChange = async (checked: boolean) => {
    setQuiz({
      ...quiz,
      config: { ...quiz.config, revealFinalScore: checked },
    })

    // save to db
    const { error } = await supabase.from('quizs').update({ config: { ...quiz.config, revealFinalScore: checked } }).eq('id', quiz.id);
    if (error) {
      toast.error('Failed to update quiz results settings');
      return
    }

    toast.success('Quiz results settings updated');
  }


  return (
    <section className="flex flex-col gap-4">
      <div>
        <div className="inline-flex items-center gap-2">
          <ChatBubbleIcon />
          <h4 className="text-lg leading-loose font-semibold">
            Quiz results display
          </h4>
        </div>
        <p className="px-6 text-sm text-neutral-600">
          Control whether correct answers should be reported back to users.
        </p>
      </div>

      <div className="px-4">
        <SwitchBox
          label="Display quiz result to students"
          description="allow your students to see their results."
          checked={quiz?.config.revealFinalScore ?? false}
          onChange={handleRevealFinalScoreChange}
        />
      </div>
      {/* <div className="px-4">
        <SwitchBox
          label="Responses feedback"
          description="For each question answered, show the user the correct answer."
          checked={false}
          disabled={true}
          onChange={() => { }}
        />
      </div> */}
    </section>
  );
}
