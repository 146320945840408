import { Button } from "@/components/ui/button";
import { Quiz } from "@/types/quiz";
import { QuizSession } from "@/types/session";
import { formatTime, humanDateFormat } from "@/lib/utils";
import {
  ArrowRightIcon,
  ClockIcon,
  LapTimerIcon,
  LayersIcon,
  LockClosedIcon,
  Pencil1Icon,
} from "@radix-ui/react-icons";
import {  format } from "date-fns";
import { Loader2Icon } from "lucide-react";
import { useEffect } from "react";

export default function QuizStartScreen({
  quiz,
  onStart,
  lastSession,
  isLoading,
  canStart,
  afterSchedule
}: {
  quiz: Omit<Quiz, "questions">;
  onStart: () => void;
  lastSession?: QuizSession;
  isLoading?: boolean;
  canStart: boolean;
  afterSchedule: boolean;
}) {
  // const [canStart, setCanStart] = useState(false);
  // const [afterSchedule, setAfterSchedule] = useState(false);

  // useEffect(() => {
  //   // if (quiz.config.timeWindow && quiz.config.timeWindow.enabled) {
  //   // check if current date passes time window duration
  //   const now = new Date()
  //   const start = new Date(quiz.config.timeWindow.start)
  //   const end = new Date(quiz.config.timeWindow.start + (quiz.config.timeWindow.duration * 60000))
  //   if (differenceInMilliseconds(now, start) > 0 && differenceInMilliseconds(now, end) < 0) {
  //     setCanStart(true);
  //   } else if (differenceInMilliseconds(now, end) > 0) {
  //     setCanStart(false);
  //     setAfterSchedule(true);
  //   } else {
  //     setCanStart(false);
  //     setAfterSchedule(false);
  //   }

  // }, [quiz.config.timeWindow])

  useEffect(() => {
    // start test if user clicks enter
    window.addEventListener("keydown", (e) => {
      if (e.isTrusted && e.key === "Enter") {
        e.preventDefault();
        onStart();
      }
    });

    return () => { };
  }, [onStart]);

  return (
    <div className="rounded bg-white p-8 border">
      <h1 className="mb-4 text-3xl font-bold text-black">{quiz.title}</h1>
      <p className="text-[9px] font-semibold text-gray-400 uppercase">
        Description
      </p>
      <p className="mb-8 text-gray-600">{quiz.description}</p>

      <h4 className="text-[9px] font-semibold text-gray-400 uppercase">
        Structure
      </h4>
      <section className="mt-2 flex flex-col gap-2">
        <div className="inline-flex items-center gap-2 text-sm">
          <LayersIcon className="text-neutral-600" />
          <p><span className="font-bold">{quiz.questionsCount[0].count}</span> questions</p>
        </div>
        <p className="inline-flex items-center gap-2 text-sm">
          <LapTimerIcon className="text-neutral-600" />{" "}
          {(!quiz.config.timer || quiz.config.timer.mode === "off") && (
            <b>No time limit</b>
          )}
          {quiz.config.timer && quiz.config.timer.mode == "perQuestion" && (
            <>
              <b>{quiz.config.timer.seconds} seconds</b> per question
            </>
          )}
          {quiz.config.timer && quiz.config.timer.mode == "total" && (
            <b>{formatTime(quiz.config.timer.seconds)}</b>
          )}
        </p>
      </section>

      <h4 className="mt-6 text-[9px] font-semibold text-gray-400 uppercase">
        Rules
      </h4>
      <section className="mt-2 flex flex-col gap-2">
        <>
          <p className="inline-flex items-center gap-2 text-sm">
            <ClockIcon className="text-neutral-600" /> <b>Quiz Time</b>
          </p>
          <p className="inline-flex items-center gap-2 px-6 text-xs">
            {format(new Date(quiz.config.timeWindow.start), humanDateFormat)}
            <ArrowRightIcon />
            {format(new Date(quiz.config.timeWindow.start + (quiz.config.timeWindow.duration * 60000)), humanDateFormat)}
          </p>
        </>
        {quiz.config.maxAttempts === 1 && (
          <p className="inline-flex items-center gap-2 text-sm">
            <Pencil1Icon className="text-neutral-600" />{" "}
            <span>
              Only <b>one</b> attempt allowed
            </span>
          </p>)}
      </section>

      <div className="flex flex-row-reverse mt-6">
        {isLoading && <Button size="lg" disabled>
          <Loader2Icon className="animate-spin" />
          Loading...
        </Button>}
        {lastSession && !lastSession?.isCompleted && <Button size="lg" asChild><a target="_blank" href={`/quiz/${lastSession.quizId}/session/${lastSession.id}`}>Resume last attempt</a></Button>}
        
        {(!lastSession || lastSession.isCompleted) && canStart &&!isLoading && (<Button onClick={onStart} size={"lg"}>
          Let's Start
          <ArrowRightIcon />
        </Button>)}

        {!canStart && afterSchedule && <Button size="lg" disabled>
          <LockClosedIcon />
          Quiz doesn't accept new responses
        </Button>}

        {!canStart && !afterSchedule && quiz.config.timeWindow && <Button size="lg" disabled>
          <ClockIcon className="text-gray-400" />
          Starts at {format(new Date(quiz.config.timeWindow.start), humanDateFormat)}</Button>}
      </div>
    </div>
  );
}
