import { supabase } from "@/lib/supabase";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
} from "@/components/ui/sidebar";
import {
  CardStackIcon,
  ExitIcon,
  LayersIcon,
  MagicWandIcon,
  PersonIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import { NavLink, useLocation } from "react-router";
import { useAuthStore } from "@/store/auth.store";
import { useWorkspaceStore } from "@/store/workspace.store";
import { WorkspaceSwitcher } from "./WorkspaceSwitcher";
import { ChevronRight, UsersIcon } from "lucide-react";

export function StudioSidebar() {
  const { user, setUser } = useAuthStore();
  const location = useLocation();
  const clearWorkspaces = useWorkspaceStore((state) => state.clear);

  const logout = async () => {
    setUser(undefined);
    clearWorkspaces();
    await supabase.auth.signOut();
  };

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <WorkspaceSwitcher />
      </SidebarHeader>
      <SidebarContent className="bg-white">
        <SidebarGroup>
          <SidebarGroupLabel>Build</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/quiz")}
                >
                  <NavLink
                    to="/studio/quizzes"
                    className="inline-flex items-center gap-2"
                  >
                    <CardStackIcon /> Quizzes
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/bank")}
                >
                  <NavLink
                    to="/studio/banks"
                    className="disabled inline-flex items-center gap-2"
                  >
                    <LayersIcon /> Question Banks
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupLabel>Manage</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/groups")}
                >
                  <NavLink
                    to="/studio/groups"
                    className="inline-flex items-center gap-2"
                  >
                    <UsersIcon /> Groups
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/students")}
                >
                  <NavLink
                    to="/studio/students"
                    className="inline-flex items-center gap-2"
                  >
                    <PersonIcon /> Students
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        {/* <SidebarGroup>
          <SidebarGroupLabel>Review</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/responses")}
                >
                  <NavLink
                    to="/studio/responses"
                    className="inline-flex items-center gap-2"
                  >
                    <ChatBubbleIcon /> Responses
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/analytics")}
                >
                  <NavLink
                    to="/studio/analytics"
                    className="inline-flex items-center gap-2"
                  >
                    <BarChartIcon /> Analytics
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup> */}
        <SidebarGroup>
          <SidebarGroupLabel>Workspace</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/branding")}
                >
                  <NavLink
                    to="/studio/branding"
                    className="inline-flex items-center gap-2"
                  >
                    <MagicWandIcon /> Logo
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {/* <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={location.pathname.startsWith("/studio/team")}
                >
                  <NavLink
                    to="/studio/team"
                    className="inline-flex items-center gap-2"
                  >
                    <PersonIcon /> Team Members
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem> */}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>

      <SidebarFooter className="bg-white">
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              isActive={location.pathname.startsWith("/studio/help")}
            >
              <NavLink to="/help" className="inline-flex items-center gap-2">
                <QuestionMarkCircledIcon /> Help center
              </NavLink>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarSeparator />
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              isActive={location.pathname.startsWith("/studio/settings")}
            >
              {/* <NavLink
                to="/studio/settings"
                className="inline-flex items-center gap-2"
              >
                <GearIcon /> Settings {user?.email}
              </NavLink> */}

              <NavLink to="/studio" className="inline-flex items-center gap-2">
                <PersonIcon />
                {user?.email}
              </NavLink>
            </SidebarMenuButton>
          </SidebarMenuItem>

          <SidebarMenuItem>
            <SidebarMenuButton onClick={logout} className="cursor-pointer">
              <ExitIcon /> Logout
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
}
