import { supabase } from "@/lib/supabase";
import { Quiz } from "@/types/quiz";

export async function getQuiz(id: string, workspaceId: string): Promise<Quiz> {
  // ensure that the quiz belongs to the workspace
  const { data, error } = await supabase
    .from("quizs")
    .select("*, responses:responses(count), questionsCount:questions(count)")
    .eq("id", id)
    .eq('workspaceId', workspaceId)
    .returns<Quiz>()
    .single();

  if (error) throw error;

  return data;
}

export async function listQuizzes(workspaceId: string) {
  const { data, error } = await supabase
    .from("quizs")
    .select(
      "id, title, description, config, created_at, questionsCount, isPublic, questionsCount:questions_quiz_id_fkey(count), responses:responses(count)",
    )
    .eq("workspaceId", workspaceId)
    .returns<Quiz[]>();

  if (error) throw error;
  return data;
}

export async function listQuizzesWithQuestions(workspaceId: string) {
  const { data, error } = await supabase
    .from("quizs")
    .select(
      "id, title, description, config, questions, created_at, questionsCount, isPublic, questionsCount:questions_quiz_id_fkey(count), responses:responses(count)",
    )
    .eq("workspaceId", workspaceId)
    .returns<Quiz[]>().order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}
