import { create } from "zustand";
import { Quiz } from "@/types/quiz";
interface EditorState {
  quiz?: Quiz;
  isEditable: boolean;
  setQuiz: (quiz: Quiz) => void;
  setIsEditable: (isEditable: boolean) => void;
}

export const useEditorStore = create<EditorState>((set) => ({
  quiz: undefined,
  isEditable: true,
  setQuiz: (quiz: Quiz) => set({ quiz }),
  setIsEditable: (isEditable: boolean) => set({ isEditable }),
}));
