import { supabase } from "@/lib/supabase";
import { Response, ResponseWithMetadata } from "@/types/response";
import { Question } from "@/types/question";

export type ResponsesFilter = { workspaceId?: string, quizId?: string, userName?: string, date?: Date };

export async function listResponses(filters: ResponsesFilter, opts?: { sortBy?: { field: string, direction: 'asc' | 'desc' } }) {
  let q = supabase
    .from("responses")
    .select(
      "id, quizId, currentElementIndex, state, reason, isCompleted, duration, score, maxScore, userId, createdAt, quiz:quizs(id, title, workspaceId), audience(*), profile:profiles(*)",
    )
  if (filters.workspaceId) {
    q = q.eq("quiz.workspaceId", filters.workspaceId).not("quiz", "is", null);
  }
  if (filters.quizId && filters.quizId != "all") {
    q = q.eq("quizId", filters.quizId);
  }
  if (filters.userName && filters.userName != "") {
    q.or(`name.ilike.%${filters.userName}%,code.ilike.%${filters.userName}%`, { referencedTable: "audience" }).not("audience", "is", null);
  }
  if (filters.date) {
    q = q.gte("createdAt", filters.date.toISOString());
  }

  if (opts && opts.sortBy) {
    q = q.order(opts.sortBy.field, { ascending: opts.sortBy.direction === 'asc' });
  } else {
    q = q.order("isCompleted", { ascending: false });
  }

  const { data, error } = await q.returns<ResponseWithMetadata[]>();
  if (error) throw error;

  return data;
}

export async function getResponseMeta(responseId: string) {
  const { data, error } = await supabase
    .from("responses")
    .select(
      "id, quizId, userId, createdAt, quiz:quizs(id, title), user:profiles!answers_userId_fkey1(*)",
    )
    .eq("id", responseId)
    .returns<Response[]>()
    .single();

  if (error) throw error;

  return data;
}

export async function getResponseDetails(responseId: string) {
  const { data, error } = await supabase
    .from("responses")
    .select(
      "id, quizId, currentElementIndex, duration, isCompleted, state, score, maxScore, userId, createdAt, elementsOrder, selectedChoices, quiz:quizs(id, title, description), profile:profiles!quiz_sessions_userId_fkey(*), audience:audience!responses_audienceId_fkey(*)",
    )
    .eq("id", responseId)
    .returns<ResponseWithMetadata[]>()
    .single();
  if (error) throw error;

  // load questions
  const { data: questions } = await supabase
    .from("questions")
    .select("id, index, question, type, choices, media, bank_id, points, correct_answer_id, created_at, updated_at")
    .in("id", data.elementsOrder)
    .returns<Question[]>()

  return { response: data, questions };
}
