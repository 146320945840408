import { BookOpen, Settings, Share2, BarChart3, Zap, HelpCircle } from "lucide-react";

export const helpCategories = [
  {
    id: "getting-started",
    title: "Getting Started",
    description: "Learn the basics of QuizBase and how to create your first quiz",
    icon: BookOpen,
    articleCount: 4
  },
  {
    id: "quiz-creation",
    title: "Quiz Creation",
    description: "Master the art of creating effective and engaging quizzes",
    icon: Settings,
    articleCount: 4
  },
  {
    id: "quiz-distribution",
    title: "Quiz Distribution",
    description: "Learn how to share your quizzes with participants",
    icon: Share2,
    articleCount: 3
  },
  {
    id: "results-analytics",
    title: "Results & Analytics",
    description: "Understand quiz results and analyze participant performance",
    icon: BarChart3,
    articleCount: 4
  },
  {
    id: "advanced-features",
    title: "Advanced Features",
    description: "Explore advanced features to enhance your quizzes",
    icon: Zap,
    articleCount: 3
  },
  {
    id: "troubleshooting",
    title: "Troubleshooting",
    description: "Find solutions to common issues and problems",
    icon: HelpCircle,
    articleCount: 4
  }
];
