import { supabase } from "../supabase";
import { Group } from "@/types/student";

/**
 * Fetch all groups for a workspace
 */
export const listGroups = async (workspaceId: string): Promise<Group[]> => {
  const { data, error } = await supabase
    .from("audience_groups")
    .select("*")
    .eq("workspaceId", workspaceId)
    .order("name", { ascending: true });

  if (error) {
    console.error("Error fetching groups:", error);
    return [];
  }

  return data || [];
};

/**
 * Check if a user belongs to a specific group
 */
export const checkUserInGroup = async (userId: string, groupId: string): Promise<boolean> => {
  // First get the audience record for this user
  const { data: audience, error: audienceError } = await supabase
    .from("audience")
    .select("*")
    .eq("profileId", userId)
    .eq("groupId", groupId)
    .single();

  if (audienceError || !audience) {
    return false;
  }

  return true;
};

/**
 * Get all groups a user belongs to in a workspace
 */
export const getUserGroups = async (userId: string, workspaceId: string): Promise<Group[]> => {
  // First get the audience records for this user in this workspace
  const { data: audiences, error: audienceError } = await supabase
    .from("audience")
    .select("groupId")
    .eq("profileId", userId)
    .eq("workspaceId", workspaceId);

  if (audienceError || !audiences || audiences.length === 0) {
    return [];
  }

  // Get the group IDs
  const groupIds: string[] = audiences.map(a => a.groupId).filter(Boolean) as string[];
  
  if (groupIds.length === 0) {
    return [];
  }

  // Fetch the groups
  const { data: groups, error: groupsError } = await supabase
    .from("audience_groups")
    .select("*")
    .in("id", groupIds);

  if (groupsError || !groups) {
    return [];
  }

  return groups;
};
